import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./pageControls.css";
import CustomHomePage from "../admin/CustomHomePage";
import AboutUs from "../extraPages/AboutUs";
import ContactUs from "../extraPages/ContactUs";
import LatestUpdates from "../extraPages/LatestUpdates";
import PrivacyPolicy from "../extraPages/PrivacyPolicy";

const PageControls = () => {
  const [homePage, setHomePage] = useState(true);
  const [aboutUs, setAboutUs] = useState(false);
  const [latestUpdates, setLatestUpdates] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [contact, setContact] = useState(false);
  const changeSideBar = (name) => {
    if (name == "home") {
      setHomePage(true);
      setAboutUs(false);
      setLatestUpdates(false);
      setPrivacyPolicy(false);
      setContact(false);
    } else if (name == "about") {
      setHomePage(false);
      setAboutUs(true);
      setLatestUpdates(false);
      setPrivacyPolicy(false);
      setContact(false);
    } else if (name == "latestupdates") {
      setHomePage(false);
      setAboutUs(false);
      setLatestUpdates(true);
      setPrivacyPolicy(false);
      setContact(false);
    } else if (name == "privacypolicy") {
      setHomePage(false);
      setAboutUs(false);
      setLatestUpdates(false);
      setPrivacyPolicy(true);
      setContact(false);
    } else if (name == "contact") {
      setHomePage(false);
      setAboutUs(false);
      setLatestUpdates(false);
      setPrivacyPolicy(false);
      setContact(true);
    }
  };

  return (
    <Container className="dashboardContainer">
      <Row>
        <Col md={3}>
          <div className="sideBarContainer">
            <h4>Pages</h4>
            <hr />
            <ul>
              <li>
                <a href="#" onClick={() => changeSideBar("home")}>
                  HomePage
                </a>
              </li>
              <li>
                <a href="#" onClick={() => changeSideBar("about")}>
                  About Us
                </a>
              </li>
              <li>
                <a href="#" onClick={() => changeSideBar("latestupdates")}>
                  Latest Updates
                </a>
              </li>
              <li>
                <a href="#" onClick={() => changeSideBar("privacypolicy")}>
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" onClick={() => changeSideBar("contact")}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </Col>
        <Col md={9}>
          <div className="">
            {homePage && <CustomHomePage />}
            {aboutUs && <AboutUs />}
            {latestUpdates && <LatestUpdates />}
            {privacyPolicy && <PrivacyPolicy />}
            {contact && <ContactUs />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PageControls;
