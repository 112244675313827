import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { firebase } from "../../firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumberSection from "./customPages/NumberSection";
import OurMissionSection from "./customPages/OurMissionSection";
import InvestSection from "./customPages/InvestSection";

const CustomHomePage = () => {
  const [bannerPromoText, setBannerPromoText] = useState("");
  const [bannerLargeText, setBannerLargeText] = useState("");
  const [bannerSmallText, setBannerSmallText] = useState("");
  const dbCon = firebase.database();
  const uploadBannerTextPromo = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("Banner")
      .child("PromoText")
      .set(
        {
          bannerPromoText,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setBannerPromoText(" ");
      });
  };
  const uploadBannerTextLarge = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("Banner")
      .child("LargeText")
      .set(
        {
          bannerLargeText,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setBannerLargeText(" ");
      });
  };

  const uploadBannerTextSmall = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("Banner")
      .child("SmallText")
      .set(
        {
          bannerSmallText,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setBannerSmallText(" ");
      });
  };
  return (
    <div>
      <ToastContainer />
      <h4 style={{ textAlign: "center", marginBottom: "15px" }}>
        Banner Section
      </h4>
      <Form onSubmit={uploadBannerTextPromo} style={{ textAlign: "left" }}>
        <Form.Group className="mb-3">
          <Form.Label>Banner Promo Text</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Banner Promo Text"
            onChange={(e) => {
              setBannerPromoText(e.target.value);
            }}
            value={bannerPromoText}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <hr />
      <Form onSubmit={uploadBannerTextLarge} style={{ textAlign: "left" }}>
        <Form.Group className="mb-3">
          <Form.Label>Banner Text Large</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Banner Text Large"
            onChange={(e) => {
              setBannerLargeText(e.target.value);
            }}
            value={bannerLargeText}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <hr />
      <Form onSubmit={uploadBannerTextSmall} style={{ textAlign: "left" }}>
        <Form.Group className="mb-3">
          <Form.Label>Banner Text Small</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Banner Text Small"
            onChange={(e) => {
              setBannerSmallText(e.target.value);
            }}
            value={bannerSmallText}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <hr />
      <hr />
      <NumberSection />
      <hr />
      <hr />
      <OurMissionSection />
      <hr />
      <hr />
      <InvestSection />
    </div>
  );
};

export default CustomHomePage;
