import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { firebase } from "../../../firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RichTextEditor from "./RichTextEditor";

const config = {
  height: "500px",
};

const OurMissionSection = () => {
  const [box1Title, setBox1Title] = useState("");
  const [box1text, setBox1text] = useState("");
  const [box2Title, setBox2Title] = useState("");
  const [box2text, setBox2text] = useState("");
  const [box3Title, setBox3Title] = useState("");
  const [box3text, setBox3text] = useState("");

  const [data, setData] = useState("");

  const uploadBox1Data = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("Mission")
      .child("Box1")
      .set(
        {
          box1Title,
          box1text,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    setBox1Title("");
    setBox1text("");
  };

  const uploadBox2Data = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("Mission")
      .child("Box2")
      .set(
        {
          box2Title,
          box2text,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    setBox2Title("");
    setBox2text("");
  };

  const uploadBox3Data = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("Mission")
      .child("Box3")
      .set(
        {
          box3Title,
          box3text,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    setBox3Title("");
    setBox3text("");
  };

  const updateRightDescription = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("Mission")
      .child("Description")
      .set(
        {
          data,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    setData(" ");
  };

  const dbCon = firebase.database();

  return (
    <div>
      <ToastContainer />
      <h4 style={{ textAlign: "center", marginBottom: "15px" }}>
        Our Mission Section
      </h4>
      <Form onSubmit={uploadBox1Data} style={{ textAlign: "left" }}>
        <Form.Group className="mb-3">
          <Form.Label>Box 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Give Title"
            onChange={(e) => {
              setBox1Title(e.target.value);
            }}
            value={box1Title}
          />
          <Form.Control
            type="text"
            placeholder="Write Text"
            onChange={(e) => {
              setBox1text(e.target.value);
            }}
            value={box1text}
            style={{ marginTop: "5px" }}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <hr />
      <Form onSubmit={uploadBox2Data} style={{ textAlign: "left" }}>
        <Form.Group className="mb-3">
          <Form.Label>Box 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Give Title"
            onChange={(e) => {
              setBox2Title(e.target.value);
            }}
            value={box2Title}
          />
          <Form.Control
            type="text"
            placeholder="Write Text"
            onChange={(e) => {
              setBox2text(e.target.value);
            }}
            value={box2text}
            style={{ marginTop: "5px" }}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <hr />
      <Form onSubmit={uploadBox3Data} style={{ textAlign: "left" }}>
        <Form.Group className="mb-3">
          <Form.Label>Box 3</Form.Label>
          <Form.Control
            type="text"
            placeholder="Give Title"
            onChange={(e) => {
              setBox3Title(e.target.value);
            }}
            value={box3Title}
          />
          <Form.Control
            type="text"
            placeholder="Write Text"
            onChange={(e) => {
              setBox3text(e.target.value);
            }}
            value={box3text}
            style={{ marginTop: "5px" }}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <Form
        onSubmit={updateRightDescription}
        style={{
          textAlign: "left",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <h4 style={{ marginTop: "5px", marginBottom: "5px" }}>
          Right Side Description
        </h4>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <RichTextEditor value={data} setValue={setData} config={config} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Save
        </Button>
      </Form>
    </div>
  );
};

export default OurMissionSection;
