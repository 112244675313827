import React from 'react'

const Contact = () => {
    return (
        <div>
            <section class="inner-page-banner-section gradient-bg">
                <div class="illustration-img"><img src="assets/images/inner-page-banner-illustrations/contact.png" alt="image-illustration" /></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="inner-page-content-area">
                                <h2 class="page-title">Contact Us</h2>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-section mt-minus pb-120">
                <div className="container">
                    <div className="contact-form-area">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="section-header text-center">
                                    <span className="section-subtitle">Contact</span>
                                    <h2 className="section-title">Get In Touch</h2>
                                    <p>Have questions? We don't bite! Just shoot us a message and we'll get back to you as soon as possible!</p>
                                </div>
                            </div>
                            <div className="col-lg-12 contact-form-wrapper">
                                <form className="contact-form" id="contact_form_submit">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="text" name="name" id="name" placeholder="Name*" />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="email" name="email" id="email" placeholder="Email*" />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="tel" name="phone" id="phone" placeholder="Phone Number*" />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" name="subject" id="subject" placeholder="Company (optional)" />
                                        </div>
                                        <div className="col-lg-12">
                                            <textarea name="message" id="message" placeholder="Message*"></textarea>
                                        </div>
                                        <div className="col-lg-12 text-center">
                                            <button type="submit" className="btn btn-primary" title="Submit Your Message!">submit now</button>
                                        </div>
                                    </div>
                                </form>
                                <p className="form-message"></p>
                            </div>
                        </div>
                    </div>
                    <div className="social-links-area pt-120">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="section-header text-center">
                                    <span className="section-subtitle">Join The Conversation!</span>
                                    <h2 className="section-title">Browse Our Social Pages</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-none-30">
                            <div className="col-lg-3 col-sm-6">
                                <div className="social-media-item text-center mb-30">
                                    <div className="icon">
                                        <i className="fa fa-facebook-f"></i>
                                    </div>
                                    <div className="content">
                                        <h3 className="counter">32,334</h3>
                                        <p>facebook members</p>
                                        <a href="#0" className="join-btn">join!</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="social-media-item text-center mb-30">
                                    <div className="icon">
                                        <i className="fa fa-twitter"></i>
                                    </div>
                                    <div className="content">
                                        <h3 className="counter">32,334</h3>
                                        <p>Twitter followers</p>
                                        <a href="#0" className="join-btn">join!</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="social-media-item text-center mb-30">
                                    <div className="icon">
                                        <i className="fa fa-instagram"></i>
                                    </div>
                                    <div className="content">
                                        <h3 className="counter">20,150</h3>
                                        <p>Instagram subscribers</p>
                                        <a href="#0" className="join-btn">join!</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="social-media-item text-center mb-30">
                                    <div className="icon">
                                        <i className="fa fa-paper-plane-o"></i>
                                    </div>
                                    <div className="content">
                                        <h3 className="counter">30,334</h3>
                                        <p>Telegram members</p>
                                        <a href="#0" className="join-btn">join!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact
