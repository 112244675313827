import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Formik } from "formik";

import "../views/Views.css"

import AuthContext from "../../AuthContext";
import { firebase } from "../../firebase";

const CapFinderModal = (props) => {

    const [stopmodal, setstopmodal] = useState(true)

    const dbCon = firebase.database();

    const CapAddInfoHandler = (email) => {
        dbCon.ref("CapAdditionalInfoReq").push(email).then(() => { setstopmodal(props.onHide) })
    }




    return (
        <div>

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Onucrom Team
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            email: '',

                        }}
                        onSubmit={(values, action) => { CapAddInfoHandler(values.email); action.resetForm(); }}
                    >
                        {({ values, handleBlur, handleChange, handleSubmit }) => (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="name">Thank you for contacting us. A representative of us would contact via email for further business discussions. Kindly confirm the email address as mentioned below</label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            placeholder="Provide Your Email"
                                            onBlur={handleBlur}
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group ModalFooterSec">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-hover"

                                        >
                                            Submit
                                        </button>
                                        <button className="btn btn-warning" onClick={props.onHide}>Cancel</button>
                                    </div>
                                </form>

                            </>
                        )}
                    </Formik>

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default CapFinderModal
