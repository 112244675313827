import React, { useState, useContext, useEffect } from "react";

import { Formik } from "formik";
import AuthContext from "../../AuthContext";
import { firebase } from "../../firebase";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as XLSX from "xlsx";
import "./onuInvest.css";
import { useHistory } from "react-router-dom"

import { Toast } from "react-bootstrap";

const fireDb = firebase.database();




const UploadFiles = () => {
    const [uploadHeader, setuploadHeader] = useState(false);
    const [Subheader2, setSubheader2] = useState("")
    const [headerClass, setheaderClass] = useState("");
    const [selectSubHeader, setselectSubHeader] = useState("")
    const [listheaderClass, setlistheaderClass] = useState([])
    const [listSubHeader, setlistSubHeader] = useState([])
    const [listSubHeader2, setlistSubHeader2] = useState([])
    const [SubHeading, setSubHeading] = useState("")
    const [loadedData, setloadedData] = useState(null)
    const [showA, setshowA] = useState(false);

    const history = useHistory();

    /* Load Header and SubHeader */
    useEffect(() => {
        fireDb.ref("OnuCrom").child("HeaderClass").on('value', (snapshot) => {
            let tempHeaderClass = []
            snapshot.forEach(snap => {
                tempHeaderClass.push(snap.val().name)
            });
            setlistheaderClass(tempHeaderClass)
        })
    }, [])

    /* Upload Header Class */
    const uploadHeaderClass = (e) => {
        e.preventDefault();
        if (!listheaderClass.includes(headerClass)) {
            fireDb
                .ref("OnuCrom")
                .child("HeaderClass")
                .push({ name: headerClass })
                .then(() => {
                    setshowA(true)
                });

        } else {
            console.log("Header Class Already Exists")
        }

    };


    /* upload SubHeader Class */
    const uploadSubHeaderClass = (e) => {
        e.preventDefault()
        fireDb
            .ref("OnuCrom")
            .child("SubHeading")
            .child(`${headerClass}`)
            .push({ name: SubHeading })
            .then(() => {
                setheaderClass("");
                setSubHeading("");
                setshowA(true)
            });
    };

    // upload subheading class 2

    const uploadSubHeaderClass2 = (e) => {
        e.preventDefault()
        fireDb
            .ref("OnuCrom")
            .child("SubHeading2")
            .child(`${headerClass}`)
            .child(`${selectSubHeader}`)
            .push({ name: Subheader2 })
            .then(() => {
                setheaderClass("");
                setSubHeading("");
                setselectSubHeader("");
                setSubheader2("");
                setshowA(true)
            });
    }

    /* upload Post */
    const fileUploadHandler = (HeaderClass, SubHeaderClass, SubheaderClass2, SectionDescription, SectionSummary, DisclaimerNotes, file, price, Author) => {
        let FileCreationDate = new Date();

        let storageRef = firebase.storage().ref(`${file.name}`)

        storageRef.put(file.name).on("state_changed", (snap) => {
            console.log()
        }, (error) => { console.log(error) }, async () => {
            const url = await storageRef.getDownloadURL();
            fireDb.ref("OnuCrom").child(`OnuInvestData/${HeaderClass}/${SubHeaderClass}`).push({
                HeaderClass,
                SubHeaderClass,
                SubheaderClass2,
                Author,
                SectionDescription,
                SectionSummary,
                DisclaimerNotes,
                FileUrl: url,
                ExcelData: loadedData,
                price,
                uploadDate: FileCreationDate.toLocaleDateString(),
                uploadTime: FileCreationDate.getHours() + '-' + FileCreationDate.getMinutes() + '-' + FileCreationDate.getSeconds()

            }).then(() => {
                setshowA(true);
                fireDb.ref("OnuCrom").child("SpecificClassDetails2").child(SubheaderClass2).set({
                    HeaderClass,
                    SubHeaderClass,
                    SubheaderClass2,
                    Author,
                    SectionDescription,
                    SectionSummary,
                    DisclaimerNotes,

                    ExcelData: loadedData,
                    price,
                    uploadDate: FileCreationDate.toLocaleDateString(),
                    uploadTime: FileCreationDate.getHours() + '-' + FileCreationDate.getMinutes() + '-' + FileCreationDate.getSeconds()

                })

            })
        })
    }

    /* Load Cascading Sub header Class */
    const CascadeHeaderClass = (headerClass) => {
        fireDb.ref("OnuCrom").child("SubHeading").child(`${headerClass}`).on('value', (snapshot) => {
            let tempHeaderClass = []

            snapshot.forEach(snap => {
                tempHeaderClass.push(snap.val().name)

            });

            setlistSubHeader(tempHeaderClass)
        });
    }

    const CascadeSubHeaderClass = (subHeaderClass) => {
        fireDb.ref("OnuCrom").child("SubHeading2").child(`${headerClass}`).child(`${subHeaderClass}`).on('value', (snapshot) => {
            let tempHeaderClass = []

            snapshot.forEach(snap => {
                tempHeaderClass.push(snap.val().name)

            });

            setlistSubHeader2(tempHeaderClass)
        });
    }




    /* View excel File */
    const loadData = (event) => {
        let selectedFile = event.target.files[0];
        var reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);

        reader.onload = function (event) {
            var ArrayData = new Uint8Array(event.target.result);


            const workbook = XLSX.read(ArrayData, { type: 'array' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            setloadedData(data)


            if (data.length > 0) {
                var table_output = '<table class="table table-striped table-bordered">';

                for (var row = 0; row < data.length; row++) {
                    table_output += '<tr>';
                    for (var cell = 0; cell < data[row].length; cell++) {
                        table_output += '<td>' + data[row][cell] + '</td>';
                    }

                    table_output += '</tr>';
                }

                table_output += '</table>';


                document.getElementById('excel_data').innerHTML = table_output;
            }
        }


    }
    return (
        <div>
            <section
                className="inner-page-banner-section gradient-bg"
                style={{ paddingTop: "165px", paddingBottom: "100px" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-xl-3">
                            <div className="SideBarContainer">
                                <h4>Controls</h4>
                                <ul>
                                    <li onClick={() => setuploadHeader(true)}>
                                        Upload Header Class
                                    </li>
                                    <li onClick={() => setuploadHeader(false)}>Upload File</li>
                                    <li onClick={() => history.push("/listDetails")}>Class Info</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xl-9">
                            <div className="MainAreaContainer">

                                <Toast show={showA} onClose={() => setshowA(false)}>
                                    <Toast.Header>

                                        <strong className="mr-auto">ONUCROM</strong>

                                    </Toast.Header>
                                    <Toast.Body>Data Uploaded!</Toast.Body>
                                </Toast>
                                {/* Formik Form */}

                                {uploadHeader ? (
                                    <div>
                                        <h4>Header Class</h4>
                                        <form onSubmit={uploadHeaderClass}>
                                            <div className="form-group">
                                                <label htmlFor="headerClass">Header Name</label>
                                                <input
                                                    type="text"
                                                    name="headerClass"
                                                    className="form-control"
                                                    placeholder="Give a Name"
                                                    value={headerClass}
                                                    onChange={(e) => {
                                                        setheaderClass(e.target.value);
                                                    }}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group Capbutton">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-hover"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                        {/* subheading 1 start */}
                                        <hr />
                                        <h4>Add SubHeading 1</h4>
                                        <form onSubmit={uploadSubHeaderClass}>
                                            <div className="form-group">
                                                <label htmlFor="">Choose Header Class</label>
                                                <select
                                                    name="HeaderClass"
                                                    className="CapDropdownSelect"
                                                    style={{ display: "block" }}
                                                    value={headerClass}
                                                    onChange={(e) => { setheaderClass(e.target.value) }}
                                                    required
                                                >
                                                    <option value="" label="---Select---" disabled />
                                                    {listheaderClass.map((value) => {
                                                        return (
                                                            <option value={value} label={value} />
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="headerClass">Subheading 1</label>
                                                <input
                                                    type="text"
                                                    name="headerClass"
                                                    className="form-control"
                                                    placeholder="Give Subheading Name"
                                                    required
                                                    value={SubHeading}
                                                    onChange={(e) => { setSubHeading(e.target.value) }}
                                                />
                                            </div>
                                            <div className="form-group Capbutton">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-hover"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                        {/* subheading 1 end */}

                                        {/* Subheading 2 start */}
                                        <hr />
                                        <h4>Add SubHeading 2</h4>
                                        <form onSubmit={uploadSubHeaderClass2}>
                                            <div className="form-group">
                                                <label htmlFor="">Choose Header Class</label>
                                                <select
                                                    name="HeaderClass"
                                                    className="CapDropdownSelect"
                                                    style={{ display: "block" }}
                                                    value={headerClass}
                                                    onChange={(e) => { setheaderClass(e.target.value); CascadeHeaderClass(e.target.value); }}
                                                    required
                                                >
                                                    <option value="" label="---Select---" disabled />
                                                    {listheaderClass.map((value) => {
                                                        return (
                                                            <option value={value} label={value} />
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Choose Subheading 1</label>
                                                <select
                                                    name="HeaderClass"
                                                    className="CapDropdownSelect"
                                                    style={{ display: "block" }}
                                                    value={selectSubHeader}
                                                    onChange={(e) => { setselectSubHeader(e.target.value); }}
                                                    required
                                                >
                                                    <option value="" label="---Select---" disabled />
                                                    {listSubHeader.map((value) => {
                                                        return (
                                                            <option value={value} label={value} />

                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="headerClass">Subheading 2</label>
                                                <input
                                                    type="text"
                                                    name="headerClass"
                                                    className="form-control"
                                                    placeholder="Give Subheading Name"
                                                    required
                                                    value={Subheader2}
                                                    onChange={(e) => { setSubheader2(e.target.value) }}
                                                />
                                            </div>
                                            <div className="form-group Capbutton">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-hover"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                        {/* Subheading 2 end */}
                                    </div>
                                ) : (
                                    <Formik
                                        initialValues={{
                                            HeaderClass: '',
                                            subheaderClass: '',
                                            subHeader2: '',
                                            SectionDescription: '',
                                            Author: "",
                                            SectionSummary: "",
                                            DisclaimerNotes: '',
                                            excelFile: null,
                                            price: "",
                                        }}

                                        onSubmit={(values, action) => {
                                            fileUploadHandler(values.HeaderClass, values.subheaderClass, values.subHeader2, values.SectionDescription, values.SectionSummary, values.DisclaimerNotes, values.excelFile, values.price, values.Author)

                                            console.log(values)
                                            action.resetForm()
                                        }}

                                    >
                                        {({ values, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                            <>
                                                <h4>Upload File for Header Classes</h4>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="form-group">
                                                        <label htmlFor="HeaderClass">Header Class</label>
                                                        <select
                                                            name="HeaderClass"
                                                            className="CapDropdownSelect"
                                                            value={values.HeaderClass}
                                                            onChange={(e) => { setheaderClass(e.target.value); CascadeHeaderClass(e.target.value); setFieldValue("HeaderClass", e.target.value) }}
                                                            onBlur={handleBlur}
                                                            style={{ display: "block" }}


                                                            required

                                                        >
                                                            <option value="" label="---Select---" disabled />
                                                            {listheaderClass.map((value) => {
                                                                return (
                                                                    <option value={value} label={value} />
                                                                )
                                                            })}

                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="subheaderClass">Select Sub Header Class</label>
                                                        <select
                                                            name="subheaderClass"
                                                            className="CapDropdownSelect"
                                                            value={values.subheaderClass}
                                                            onChange={(e) => { setselectSubHeader(e.target.value); setFieldValue("subheaderClass", e.target.value); CascadeSubHeaderClass(e.target.value); }}
                                                            onBlur={handleBlur}
                                                            style={{ display: "block" }}
                                                            required
                                                        >
                                                            <option value="" label="---Select---" disabled />
                                                            {listSubHeader.map((value) => {
                                                                return (
                                                                    <option value={value} label={value} />

                                                                )
                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="subHeader2">Select Sub Header Class 2</label>
                                                        <select
                                                            name="subHeader2"
                                                            className="CapDropdownSelect"
                                                            value={values.subHeader2}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            style={{ display: "block" }}
                                                            required
                                                        >
                                                            <option value="" label="---Select---" disabled />
                                                            {listSubHeader2.map((value) => {
                                                                return (
                                                                    <option value={value} label={value} />

                                                                )
                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="Author">Uploader*</label>
                                                        <input
                                                            type="text"
                                                            name="Author"
                                                            className="form-control"
                                                            placeholder="Author Name"
                                                            onBlur={handleBlur}
                                                            value={values.Author}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group" >
                                                        <label htmlFor="SectionDescription">Write Section Description</label>
                                                        <textarea

                                                            name="SectionDescription"
                                                            className="form-control"
                                                            placeholder="Provide short description"
                                                            onBlur={handleBlur}
                                                            value={values.SectionDescription}
                                                            onChange={handleChange}
                                                            rows="3"
                                                        ></textarea>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="price">Key Statistics*</label>
                                                        <input
                                                            type="text"
                                                            name="price"
                                                            className="form-control"
                                                            placeholder="What is the price"
                                                            onBlur={handleBlur}
                                                            value={values.price}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>



                                                    <div className="form-group" style={{ height: "500px" }}>
                                                        <label htmlFor="Brief">Write Section Summary</label>
                                                        <ReactQuill style={{ height: "430px" }} className="editor" name="SectionSummary" value={values.SectionSummary} theme="snow"
                                                            onChange={(e) => { setFieldValue("SectionSummary", e) }} modules={modules} format={format} />

                                                    </div>

                                                    <div className="form-group" style={{ height: "300px" }}>
                                                        <label htmlFor="Brief">Write Disclaimer Notes</label>
                                                        <ReactQuill style={{ height: "240px" }} className="editor" name="DisclaimerNotes" value={values.DisclaimerNotes} theme="snow"
                                                            onChange={(e) => { setFieldValue("DisclaimerNotes", e) }} modules={modules} format={format} />

                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="Brief">Upload Excel File</label>
                                                        <input type="file" name="excelFile" value={undefined} className="excel_file" onChange={(e) => { setFieldValue("excelFile", e.target.files[0]); loadData(e) }} />
                                                        <div className="Excel_form" id="excel_data">

                                                        </div>
                                                    </div>
                                                    <div id="excel_data"></div>
                                                    <div className="form-group Capbutton">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary btn-hover"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </form>

                                            </>


                                        )}
                                    </Formik>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline",],
        [{ header: "1" }, { header: "2" }, { header: "3" }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ["clean"]
    ],
}

const format = [

    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'header',
    'list',
    'bullet'

]


export default UploadFiles;
