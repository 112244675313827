import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { firebase } from "../../../firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InvestSection = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const dbCon = firebase.database();
  const uploadDataHandler = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("Investment")

      .set(
        {
          title,
          description,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTitle(" ");
        setDescription(" ");
      });
  };

  return (
    <div>
      {" "}
      <ToastContainer />
      <h4 style={{ textAlign: "center", marginBottom: "15px" }}>
        Investment Section
      </h4>
      <Form onSubmit={uploadDataHandler} style={{ textAlign: "left" }}>
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Give Title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          />
          <Form.Label>Write Section Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Write Description"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            style={{ marginTop: "5px" }}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default InvestSection;
