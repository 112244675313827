import React, { useState, useEffect } from 'react'
import { firebase } from "../../firebase";
import "./onuInvest.css"

import { Link, useHistory } from 'react-router-dom';
const fireDb = firebase.database();

const ShowData = () => {


    const [specificClassData, setspecificClassData] = useState({});
    const [listheaderClass, setlistheaderClass] = useState([])
    const [listSubHeader, setlistSubHeader] = useState({})

    const history = useHistory()

    const loadHeaderClass = () => {
        fireDb.ref("OnuCrom").child("HeaderClass").on('value', (snapshot) => {
            let tempHeaderClass = []
            snapshot.forEach(snap => {
                tempHeaderClass.push(snap.val().name)

            });
            setlistheaderClass(tempHeaderClass)
        })
    }

    const loadSubheaderClass = () => {
        fireDb.ref("OnuCrom").child("SubHeading").on('value', (snapshot) => {
            /* snapshot.forEach(snap => {
                console.log(snap.val())
            }) */
            setlistSubHeader(snapshot.val())

        })
    }

    const loadAllSubHeaderData = () => {

    }

    const loadUploadedData = () => {
        fireDb.ref("OnuCrom").child("SpecificClassDetails").on('value', snapshot => {
            setspecificClassData(snapshot.val())
        })
    }

    useEffect(() => {
        loadHeaderClass();
        loadSubheaderClass();
        loadUploadedData();
    }, [])

    const DeleteItem = (value) => {
        //window.location.reload()
        if (window.confirm("Are you Sure to Delete an item? ")) {
            fireDb.ref("OnuCrom").child(`SpecificClassDetails/${value}`).remove((err) => {
                if (err) {
                    console.log(err)
                } else {
                    console.log("success")

                }
            })
        }
    }

    return (
        <section
            className="inner-page-banner-section gradient-bg"
            style={{ paddingTop: "165px", paddingBottom: "100px" }}
        >
            <div className="container">
                <div className="row">
                    <div className="listInfoContainer GoBackContainer col-lg-12 col-md-12 col-xl-12">

                        <h4>View All Updated Information</h4>
                        <button className="btn btn-primary" onClick={() => { history.push("/onuUpload") }}>Go Back</button>


                    </div>
                </div>
                <div className="row">

                    <div className="listInfoContainer col-xl-12 col-md-12 col-lg-12 col-12">
                        <h4>Header Classes</h4>
                        <hr />
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl.</th>
                                    <th>Name</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {listheaderClass.map((value, index) => {

                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{value}</td>
                                            <td> <button className="btn btn-danger" onClick={() => { history.push("/onuUpload") }}>Edit</button>

                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="listInfoContainer col-xl-12 col-md-12 col-lg-12 col-12">
                        <h4>Header Classes - Sub Category</h4>
                        <hr />

                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl.</th>
                                    <th>Main Class Name</th>
                                    <th>Sub Category 1</th>
                                    <th>Sub Category 2</th>

                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(listSubHeader).map((value, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{value}</td>
                                            <td>
                                                {Object.keys(listSubHeader[value]).map((val, id) => {
                                                    return (
                                                        <>
                                                            {listSubHeader[value][val].name}
                                                            <br />
                                                            <hr />
                                                        </>
                                                    )
                                                })}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="listInfoContainer col-xl-12 col-md-12 col-lg-12 col-12">
                        <h4>Uploaded file for Header Classes</h4>
                        <hr />
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl.</th>
                                    <th>Header Class</th>
                                    <th>Sub Header 1</th>
                                    <th>Sub Header 2</th>
                                    <th>Uploader</th>
                                    <th>Key Statistics</th>
                                    <th>Uploaded Date</th>
                                    <th>Uploaded Time</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(specificClassData).map((value, id) => {
                                    return (
                                        <tr>
                                            <td>{id + 1}</td>
                                            <td>{specificClassData[value].HeaderClass}</td>
                                            <td>{specificClassData[value].SubHeaderClass}</td>
                                            <td>{specificClassData[value].SubheaderClass2}</td>
                                            <td>{specificClassData[value].Author}</td>
                                            <td>{specificClassData[value].price}</td>
                                            <td>{specificClassData[value].uploadDate}</td>
                                            <td>{specificClassData[value].uploadTime}</td>
                                            <td className='ShowdataActionBtnContainer'>

                                                <button className="btn btn-success" onClick={() => history.push(`Edit/${value}`)}>Edit</button>

                                                <button className="btn btn-danger" onClick={() => DeleteItem(value)}>Delete</button>
                                            </td>



                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ShowData
