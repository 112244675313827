import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import react, { useState, useEffect } from "react";

import { firebase } from "./firebase";

import Header from "./pages/homepage/Header.js";
import Main from "./pages/homepage/Main";
import Footer from "./pages/homepage/Footer";
import Signin from "./pages/authentication/Signin";
import Signup from "./pages/authentication/Signup";
import AuthContext from "./AuthContext";
import Dashboard from "./pages/user/Dashboard";

import ProtectedRoute from "./pages/authentication/ProtectedRoute";
import AdminDashboard from "./pages/admin/AdminDashboard";
import CapitalGate from "./pages/admin/CapitalGate";
import InfoBase from "./pages/user/InfoBase";
import Contact from "./pages/user/Contact";
import Cover from "./pages/user/Cover";
import SpecificClass from "./pages/user/SpecificClass";

import SpecificClassDetails from "./pages/user/SpecificClassDetails";
import OnuInvest from "./pages/user/OnuInvest";
import UploadFiles from "./pages/onuInvest/UploadFiles";
import ShowData from "./pages/onuInvest/ShowData";
import SpecificClassDetailsInfo from "./pages/onuInvest/SpecificClassDetailsInfo";
import ShowDatabyClass2 from "./pages/onuInvest/ShowDatabyClass2";
import OnuInvestHomePage from "./pages/onuInvest/OnuInvestHomePage";
import EditContent from "./pages/onuInvest/EditContent";

function App() {
  const [user, setUser] = useState(null);
  const [isAuthorized, setisAuthorized] = useState(false);

  var authListener = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user.uid);
      } else {
        setUser(null);
      }
    });
  };
  //console.log(isAuthorized);

  useEffect(() => {
    authListener();
    if (user != null) {
      setisAuthorized(true);
    } else {
      setisAuthorized(false);
    }
    console.log(isAuthorized);
  }, [user, isAuthorized]);
  console.log(isAuthorized);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <Router>
        <div>
          <Header />
          <Switch>
            <Route exact path="/">
              <Main />
            </Route>
            <Route path="/login">
              <Signin />
            </Route>
            <Route path="/signUp">
              <Signup />
            </Route>
            {/* <ProtectedRoute
              path="/dashboard"
              component={Dashboard}
              isAuth={isAuthorized}
            /> */}
            <Route path="/dashboard">
              <Dashboard />
            </Route>

            <Route path="/admin">
              <AdminDashboard />
            </Route>
            <Route path="/CapitalGate">
              <CapitalGate />
            </Route>
            <Route path="/InfoBase">
              <InfoBase />
            </Route>
            <Route path="/Contact">
              <Contact />
            </Route>

            <Route path="/SpecificClass">
              <SpecificClass />
            </Route>

            {/*  <Route path="/onuInvest">
              <OnuInvest />
            </Route> */}

            <Route path="/onuUpload">
              <UploadFiles />
            </Route>
            <Route path="/listDetails">
              <ShowData />
            </Route>
            <Route path="/details/:id">
              <SpecificClassDetailsInfo />
            </Route>
            <Route path="/onuInvest">
              <OnuInvestHomePage />
            </Route>

            <Route path="/Edit/:id">
              <EditContent />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
