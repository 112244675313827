import React, { useState, useContext } from 'react'

import "../admin/Admin.css"

import { Formik } from "formik";
import * as Yup from "yup";
import CapFinderModal from '../views/CapFinderModal';

import AuthContext from "../../AuthContext";
import { firebase } from "../../firebase";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as XLSX from "xlsx";

const UploadFiles = () => {
    const [showAlert, setshowAlert] = useState(false)
    const userData = useContext(AuthContext)





    const fileUploadHandler = (HeaderClass, fileName, Subject, Author, Class, file, price, Brief) => {
        let FileCreationDate = new Date()

        let storageRef = firebase.storage().ref(`${file.name}`)
        let uploadTask = storageRef.put(file)

        const num = Math.floor(Math.random() * (100 - 1 + 1)) + 1;

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, () => {
            storageRef.getDownloadURL().then((res) => {
                firebase.database().ref("UploadFiles").child(userData.user).child(HeaderClass).child(num).set({
                    HeaderClass,
                    FileName: fileName,
                    Subject,
                    Author,
                    Class,
                    FileUrl: res,
                    price,
                    Brief,
                    UploadDate: FileCreationDate.toDateString()
                },
                    (error) => {
                        if (error) {
                            console.log(error);
                        }
                        else {
                            setshowAlert(true)
                        }
                    })
            })

        })



        /* uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, () => {
            firebase.database().ref("UploadFiles").child(userData.user).set({
                HeaderClass,
                Subject,
                Author,
                Class,
                FileUrl: uploadTask.snapshot.getDownloadUrl,
                price,
                Brief,
                UploadDate: FileCreationDate
            },
                (error) => {
                    if (error) {
                        console.log(error);
                    }
                    else {
                        setshowAlert(true)
                    }
                })
        }) */


    }
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <h4 className="CapFormHeading">Upload File</h4>

                    <Formik
                        initialValues={{
                            HeaderClass: '',
                            FileName: '',
                            Subject: '',
                            Author: "",
                            Class: '',
                            File: '',
                            price: '',
                            Brief: ''
                        }}

                        onSubmit={(values, action) => {
                            fileUploadHandler(values.HeaderClass, values.FileName, values.Subject, values.Author, values.Class, values.File, values.price, values.Brief)

                            action.resetForm()
                        }}

                    >
                        {({ values, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="HeaderClass">Header Class</label>
                                        <select
                                            name="HeaderClass"
                                            className="CapDropdownSelect"
                                            value={values.HeaderClass}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ display: "block" }}
                                            required
                                        >
                                            <option value="" label="---Select---" disabled />
                                            <option value="Share" label="Share" />
                                            <option value="Industry" label="Industry" />
                                            <option value="MutualF" label="Mutual Funds" />
                                            <option value="Bonds" label="Bonds" />

                                        </select>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="FileName">File Name*</label>
                                        <input
                                            type="text"
                                            name="FileName"
                                            className="form-control"
                                            placeholder="Give a Name"
                                            onBlur={handleBlur}
                                            value={values.FileName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Subject">Subject*</label>
                                        <input
                                            type="text"
                                            name="Subject"
                                            className="form-control"
                                            placeholder="Write Subject"
                                            onBlur={handleBlur}
                                            value={values.Subject}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Author">Author*</label>
                                        <input
                                            type="text"
                                            name="Author"
                                            className="form-control"
                                            placeholder="Author Name"
                                            onBlur={handleBlur}
                                            value={values.Author}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="Class">Class*</label>
                                        <input
                                            type="text"
                                            name="Class"
                                            className="form-control"
                                            placeholder="Provide Class "
                                            onBlur={handleBlur}
                                            value={values.Class}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="price">Price*</label>
                                        <input
                                            type="number"
                                            name="price"
                                            className="form-control"
                                            placeholder="What is the price"
                                            onBlur={handleBlur}
                                            value={values.price}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="File">Upload File*</label>
                                        <input
                                            type="file"
                                            name="File"
                                            className="form-control"
                                            placeholder="Provide Your Name"


                                            required
                                        />
                                    </div>
                                    <div id="excel_data"></div>

                                    <div className="form-group">
                                        <label htmlFor="Brief">Brief Description</label>
                                        <textarea
                                            type="number"
                                            name="Brief"
                                            className="form-control"
                                            placeholder="Provide short description"
                                            onBlur={handleBlur}
                                            value={values.Brief}
                                            onChange={handleChange}
                                            rows="3"
                                        ></textarea>
                                    </div>






                                    <div className="form-group Capbutton">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-hover"
                                        >
                                            Submit
                                        </button>



                                    </div>
                                </form>

                            </>
                        )}
                    </Formik>

                    <Alert show={showAlert} variant="success">
                        <Alert.Heading>Onucrom Team!</Alert.Heading>
                        <p>
                            File Uploaded
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <button onClick={() => setshowAlert(false)} variant="outline-success">
                                Close
                            </button>
                        </div>
                    </Alert>



                </div>


            </div>


        </div>
    )
}

export default UploadFiles

