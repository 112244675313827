import React, { useState, useEffect } from 'react'
import "./onuInvest.css";
import { firebase } from "../../firebase";
import { useParams } from "react-router-dom"

import { FaEye } from "react-icons/fa";
import parse from 'html-react-parser';


const fireDb = firebase.database();

const SpecificClassDetailsInfo = () => {

    const [data, setdata] = useState({})
    const [header, setheader] = useState([])
    const [fileData, setfileData] = useState(null)
    const [isLoading, setisLoading] = useState(true)
    const [showDescription, setshowDescription] = useState(true)


    const { id } = useParams()
    const loadDatafromDb = () => {
        fireDb.ref("OnuCrom").child(`SpecificClassDetails/${id}`).once('value', (snapshot) => {

            setdata(snapshot.val())
            setheader(snapshot.val().ExcelData[0])
            setfileData(snapshot.val().ExcelData.slice(1,))
        }).then(() => { setisLoading(false) })


    }



    useEffect(() => {
        loadDatafromDb()
    }, [])

    if (isLoading) {
        return (
            <div>
                Loading......
            </div>
        )
    }
    return (
        <section
            className="inner-page-banner-section gradient-bg"
            style={{ paddingTop: "165px", paddingBottom: "100px" }}
        >
            <div className='container' style={{ maxWidth: '1400px', display: 'flex' }}>
                {/* <SidebarComponent /> */}
                <div className='contentSectionContainer'>
                    {/* Section 1  */}
                    <div className='headerInfo'>
                        <h3>{data.SubheaderClass2} <span onClick={() => { setshowDescription(!showDescription) }}><FaEye /></span></h3>
                        <hr />
                        {showDescription ? <p>{data.SectionDescription}</p> : ""}
                    </div>
                    {/* Section 2  */}
                    <div className='tableContainer'>
                        <table className="table table-striped table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    {

                                        header.map((value) => {

                                            return (
                                                <th>
                                                    {value}
                                                </th>
                                            )
                                        })

                                    }


                                </tr>
                            </thead>
                            <tbody>
                                {


                                    fileData.map((val, index) => {

                                        return (
                                            <tr>
                                                {val.map((dt, id) => {
                                                    return (
                                                        <td>{dt}</td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>

                        </table>
                    </div>

                    {/* Section 3  */}

                    <div className='summaryContainer'>
                        <h4>Summary </h4>
                        <hr />
                        {parse(data.SectionSummary)}
                    </div>
                    {/* Section 4  */}
                    <div className='disclaimerContainer'>
                        <h4>Disclaimer</h4>
                        <hr />
                        {parse(data.DisclaimerNotes)}
                    </div>

                </div>
            </div>

        </section>
    )
}

export default SpecificClassDetailsInfo
