import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { firebase } from "../../../firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NumberSection = () => {
  const [number1Text, setNumber1Text] = useState("");
  const [number1num, setNumber1num] = useState(0);

  const [number2Text, setNumber2Text] = useState("");
  const [number2num, setNumber2num] = useState(0);

  const [number3Text, setNumber3Text] = useState("");
  const [number3num, setNumber3num] = useState(0);

  const dbCon = firebase.database();

  const uploadNumberSection1Data = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("NumberSection")
      .child("Section1")
      .set(
        {
          number1Text,
          number1num,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNumber1Text(" ");
        setNumber1num(0);
      });
  };
  const uploadNumberSection2Data = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("NumberSection")
      .child("Section2")
      .set(
        {
          number2Text,
          number2num,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNumber2Text(" ");
        setNumber2num(0);
      });
  };

  const uploadNumberSection3Data = (e) => {
    e.preventDefault();
    dbCon
      .ref("HomePage")
      .child("NumberSection")
      .child("Section3")
      .set(
        {
          number3Text,
          number3num,
        },
        (error) => {
          console.log(error);
        }
      )
      .then(() => {
        toast.success("Upload Completed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNumber3Text(" ");
        setNumber3num(0);
      });
  };
  return (
    <div>
      <ToastContainer />
      <h4 style={{ textAlign: "center", marginBottom: "15px" }}>
        Number Information Section
      </h4>
      <Form onSubmit={uploadNumberSection1Data} style={{ textAlign: "left" }}>
        <Form.Group className="mb-3">
          <Form.Label>Number Section 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Give Title"
            onChange={(e) => {
              setNumber1Text(e.target.value);
            }}
            value={number1Text}
          />
          <Form.Control
            type="numbers"
            placeholder="Write a Number"
            onChange={(e) => {
              setNumber1num(e.target.value);
            }}
            value={number1num}
            style={{ marginTop: "5px" }}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <hr />
      <Form onSubmit={uploadNumberSection2Data} style={{ textAlign: "left" }}>
        <Form.Group className="mb-3">
          <Form.Label>Number Section 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Give Title"
            onChange={(e) => {
              setNumber2Text(e.target.value);
            }}
            value={number2Text}
          />
          <Form.Control
            type="numbers"
            placeholder="Write a Number"
            onChange={(e) => {
              setNumber2num(e.target.value);
            }}
            value={number2num}
            style={{ marginTop: "5px" }}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <hr />
      <Form onSubmit={uploadNumberSection3Data} style={{ textAlign: "left" }}>
        <Form.Group className="mb-3">
          <Form.Label>Number Section 3</Form.Label>
          <Form.Control
            type="text"
            placeholder="Give Title"
            onChange={(e) => {
              setNumber3Text(e.target.value);
            }}
            value={number3Text}
          />
          <Form.Control
            type="numbers"
            placeholder="Write a Number"
            onChange={(e) => {
              setNumber3num(e.target.value);
            }}
            value={number3num}
            style={{ marginTop: "5px" }}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NumberSection;
