import React from 'react'
import "../admin/Admin.css"

const Pricing = () => {
    return (
        <div>
            <h3 className="PriceLabel">Pricing</h3>

            <form >
                <div className="form-group">
                    <label htmlFor="HeaderClass">Select pricing lebel</label>
                    <select
                        name="HeaderClass"
                        className="CapDropdownSelect"

                        style={{ display: "block" }}
                        required
                    >
                        <option value="" label="---Select---" disabled />
                        <option value="Share" label="Placeholder 1" />
                        <option value="Industry" label="Placeholder 2" />
                        <option value="MutualF" label="Placeholder 3" />
                        <option value="Bonds" label="Placeholder 4" />

                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="FileName">Set Value*</label>
                    <input
                        type="text"
                        name="FileName"
                        className="form-control"
                        placeholder="Set price value"

                        required
                    />
                </div>
                <div className="form-group Capbutton">
                    <button
                        type="submit"
                        className="btn btn-primary btn-hover"
                    >
                        Submit
                    </button>
                </div>

            </form>

            <div>
                <h3 className="PriceLabel">Banner Settings</h3>

                <form >
                    <div className="form-group">
                        <label htmlFor="HeaderClass">Select Page</label>
                        <select
                            name="HeaderClass"
                            className="CapDropdownSelect"

                            style={{ display: "block" }}
                            required
                        >
                            <option value="" label="---Select---" disabled />
                            <option value="Share" label="Home" />
                            <option value="Industry" label="Capital Gate" />
                            <option value="MutualF" label="Infobase" />
                            <option value="Bonds" label="Contact" />

                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="HeaderClass">Select Page Section</label>
                        <select
                            name="HeaderClass"
                            className="CapDropdownSelect"

                            style={{ display: "block" }}
                            required
                        >
                            <option value="" label="---Select---" disabled />
                            <option value="Share" label="Placeholder 1" />
                            <option value="Industry" label="Placeholder 2" />
                            <option value="MutualF" label="Placeholder 3" />
                            <option value="Bonds" label="Placeholder 4" />

                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="FileName">Set Ad link*</label>
                        <input
                            type="text"
                            name="FileName"
                            className="form-control"
                            placeholder="Provide Link"

                            required
                        />
                    </div>
                    <div className="form-group Capbutton">
                        <button
                            type="submit"
                            className="btn btn-primary btn-hover"
                        >
                            Submit
                        </button>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default Pricing

