import React, { useState, useEffect } from 'react'
import DisplayContent from './DisplayContent'

import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaMixer } from "react-icons/fa";

import { firebase } from "../../firebase";
import "./onuInvest.css"

import { Link, useHistory } from 'react-router-dom';
const fireDb = firebase.database();

const OnuInvestHomePage = (props) => {
    const [specificClassData, setspecificClassData] = useState({});
    const [LoadSubsection, setLoadSubsection] = useState(false)
    const [Link, setLink] = useState(null)
    const [Id, setId] = useState(null)

    // Code for sidebar
    const [isCollapsed, setisCollapsed] = useState(false);
    const [headerClass, setheaderClass] = useState([]);
    const [subheaderClass, setsubheaderClass] = useState([]);
    const [subheaderClass2, setsubheaderClass2] = useState([])

    const [tempHeaderClass, settempHeaderClass] = useState()
    const [tempSubHeaderClass, settempSubHeaderClass] = useState()

    const loadHeader = () => {
        fireDb.ref("OnuCrom").child("HeaderClass").once('value', (snapshot) => {

            let tempHeaderClass = []
            snapshot.forEach(snap => {
                tempHeaderClass.push(snap.val().name)

            });
            setheaderClass(tempHeaderClass)
        })
    }

    const loadSubHeading = (val) => {
        fireDb.ref("OnuCrom").child(`SubHeading/${val}`).once('value', (snapshot) => {

            let tempHeaderClass = []
            snapshot.forEach(snap => {
                tempHeaderClass.push(snap.val().name)

            });
            setsubheaderClass(tempHeaderClass)
        })
    }

    const loadSubHeading2 = (val, val2) => {
        fireDb.ref("OnuCrom").child(`SubHeading2/${val}/${val2}`).once('value', (snapshot) => {

            let tempHeaderClass = []
            snapshot.forEach(snap => {
                tempHeaderClass.push(snap.val().name)

            });
            setsubheaderClass2(tempHeaderClass)
        })
    }
    // Code for sidebar ends

    var history = useHistory()

    const loadUploadedData = () => {
        fireDb.ref("OnuCrom").child("OnuInvestData").on('value', snapshot => {
            setspecificClassData(snapshot.val())
        })
    }

    useEffect(() => {
        loadHeader()
        loadUploadedData();
    }, [])
    console.log(specificClassData)
    return (
        <section
            className="inner-page-banner-section gradient-bg"
            style={{ paddingTop: "165px", paddingBottom: "100px" }}
        >
            <div className='container' style={{ maxWidth: '1400px', display: 'flex' }}>
                <div>
                    <ProSidebar collapsed={isCollapsed} style={{ zIndex: '0' }}>
                        <SidebarHeader>
                            <div
                                style={{
                                    padding: '24px',
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    letterSpacing: '1px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    cursor: 'pointer'
                                }}
                                onClick={() => { setisCollapsed(!isCollapsed) }}
                            >
                                MENU
                                <FaMixer />
                            </div>
                        </SidebarHeader>
                        <SidebarContent>
                            {
                                headerClass.map((val) => {
                                    return (
                                        <Menu iconShape="square">

                                            <SubMenu title={val} onClick={() => {
                                                loadSubHeading(val)
                                            }}>
                                                {
                                                    subheaderClass.map((val2) => {
                                                        return (
                                                            <SubMenu title={val2} onClick={() => { loadSubHeading2(val, val2) }}>
                                                                {
                                                                    subheaderClass2.map((val3) => {
                                                                        return (
                                                                            <MenuItem onClick={() => { setLoadSubsection(true); setLink(val3) }}>{val3}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </SubMenu>
                                                        )
                                                    })
                                                }

                                            </SubMenu>
                                        </Menu>
                                    )
                                })
                            }

                        </SidebarContent>
                        <SidebarFooter>
                            {/**
     *  You can add a footer for the sidebar ex: copyright
     */}
                        </SidebarFooter>

                    </ProSidebar>
                </div>

                {/* <DisplayContent /> */}
                <div className="contentSectionContainer">
                    <div className="listInfoContainer col-xl-12 col-md-12 col-lg-12 col-12">
                        {
                            LoadSubsection ? <DisplayContent link={Link} id={`${tempHeaderClass}/${tempSubHeaderClass}/${Id}`} /> :
                                Object.keys(specificClassData).map((value, id) => {
                                    return (<>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h4>{value}</h4>
                                            {
                                                LoadSubsection ? <button className='btn btn-primary' onClick={() => { setLoadSubsection(false) }}>Back to List</button> : " "
                                            }
                                        </div>
                                        <hr />

                                        {Object.keys(specificClassData[value]).map((data, i) => {
                                            return (
                                                <>
                                                    {
                                                        <table className="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sl.</th>

                                                                    <th>{data}</th>

                                                                    <th>Key Statistics</th>


                                                                    <th>Action</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    Object.keys(specificClassData[value][data]).map((d, i) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{i + 1}</td>
                                                                                <td>{specificClassData[value][data][d].SubheaderClass2}</td>
                                                                                <td>{specificClassData[value][data][d].price}</td>
                                                                                <td className='ShowdataActionBtnContainer'>

                                                                                    <button className="btn btn-success" onClick={() => { setId(d); settempHeaderClass(value); settempSubHeaderClass(data); setLoadSubsection(true) }}>View</button>


                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>

                                                        </table>
                                                    }
                                                </>
                                            )
                                        })}</>
                                    )
                                })
                        }

                        {/*  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h4>Onu Invest</h4>
                            {
                                LoadSubsection ? <button className='btn btn-primary' onClick={() => { setLoadSubsection(false) }}>Back to List</button> : " "
                            }
                        </div>
                        <hr />
                        {
                            LoadSubsection ? <DisplayContent link={Link} id={Id} /> :
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>Header Class</th>
                                            <th>Sub Header 1</th>
                                            <th>Sub Header 2</th>

                                            <th>Key Statistics</th>
                                            <th>Uploaded Date</th>

                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(specificClassData).map((value, id) => {
                                            return (
                                                <tr>
                                                    <td>{id + 1}</td>
                                                    <td>{specificClassData[value].HeaderClass}</td>
                                                    <td>{specificClassData[value].SubHeaderClass}</td>
                                                    <td>{specificClassData[value].SubheaderClass2}</td>

                                                    <td>{specificClassData[value].price}</td>
                                                    <td>{specificClassData[value].uploadDate}</td>

                                                    <td className='ShowdataActionBtnContainer'>

                                                        <button className="btn btn-success" onClick={() => { setId(value); setLoadSubsection(true) }}>View</button>


                                                    </td>



                                                </tr>
                                            )
                                        })}
                                    </tbody>

                                </table>
                        } */}
                    </div>
                </div>
            </div>
        </section>

    )
}

export default OnuInvestHomePage
