import React, { useContext, useEffect, useState } from "react";
import { firebase } from "../../firebase";

const Main = () => {
  const dbCon = firebase.database();
  const [homepageData, sethomepageData] = useState();
  const [Banner, setBanner] = useState();
  const [Investment, setInvestment] = useState();
  const [Session, setSession] = useState();
  const [NumberSection, setNumberSection] = useState();
  useEffect(() => {
    dbCon.ref("HomePage/").on("value", (snapshot) => {
      setBanner(snapshot.val().Banner);
      setInvestment(snapshot.val().Investment);
      setSession(snapshot.val().Session);
      setNumberSection(snapshot.val().NumberSection);
    });
  }, []);

  return (
    <div>
      {/*banner-section start */}
      <section className="banner-section banner--four">
        <div
          className="banner-shape bg_img"
          data-background="assets/images/elements/home4-banner-shape.png"
        ></div>
        <div className="banner-elements-image anim-bounce">
          <img src="assets/images/elements/home4-banner-el.png" alt="image" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="banner-content-area">
                <div className="banner-content">
                  <span className="banner-sub-title">
                    {Banner && Banner.PromoText.bannerPromoText}
                  </span>
                  <h2 className="banner-title text-white">
                    {Banner && Banner.LargeText.bannerLargeText}
                  </h2>
                  <p className="text-white">
                    {Banner && Banner.SmallText.bannerSmallText}
                  </p>
                </div>
                <div className="banner-btn-area">
                  <a href="#0" className="btn btn-info btn-round">
                    get started now!
                  </a>
                  <a href="#0" className="video-btn video-btn--info">
                    <span className="icon">
                      <i className="icofont-ui-play"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*banner-section end */}
      {/* counter-section start */}
      <div className="counter-sections pt-lg-0 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="counter-area style--two d-flex justify-content-between">
                <div className="counter-item">
                  <div className="counter-icon">
                    <img src="assets/images/icons/counter/1.svg" alt="icon" />
                  </div>
                  <div className="counter-content">
                    <span className="counter">2500</span>
                    <span>+</span>
                    <span className="caption">Investment Choices</span>
                  </div>
                </div>
                {/* counter-item end */}
                <div className="counter-item">
                  <div className="counter-icon">
                    <img src="assets/images/icons/counter/2.svg" alt="icon" />
                  </div>
                  <div className="counter-content">
                    <span className="counter">30</span>
                    <span>+</span>
                    <span className="caption">Investment Categories</span>
                  </div>
                </div>
                {/* counter-item end */}
                <div className="counter-item">
                  <div className="counter-icon">
                    <img src="assets/images/icons/counter/3.svg" alt="icon" />
                  </div>
                  <div className="counter-content">
                    <span className="counter">150</span>
                    <span>+</span>
                    <span className="caption">Institutional Coverage</span>
                  </div>
                </div>
                {/* counter-item end */}
              </div>
            </div>
          </div>
        </div>
      </div>
      ;{/* counter-section end */}
      {/* choose-us-section start */}
      <section className="choose-us-section pt-120 pb-120">
        <div className="round-shape-el">
          <img
            src="assets/images/elements/choose-us-circle-shape.png"
            alt="image"
          />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-header text-center">
                <span className="section-subtitle">Amazing Services</span>
                <h2 className="section-title">Why Should Choose Us</h2>
                <p>
                  Our service gives you better result and savings, as per your
                  requirement and you can manage youer investments from anywhere
                  either form home or work place,any time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4">
              <div className="choose-us-thumb d-xl-block d-none anim-bounce">
                <img
                  src="assets/images/elements/choose-us-img.png"
                  alt="image"
                />
              </div>
            </div>
            <div className="col-xl-8">
              <div className="choose-us-slider__three owl-carousel">
                <div className="choose-item text-center">
                  <div className="choose-thumb">
                    <img src="assets/images/choose-us/1.png" alt="image" />
                  </div>
                  <div className="choose-content">
                    <h3 className="title">Service 1</h3>
                    <p>A description of an effort and why it matters</p>
                    <a href="#0" className="read-more-btn">
                      read more<i className="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
                {/* choose-item end */}
                <div className="choose-item text-center">
                  <div className="choose-thumb">
                    <img src="assets/images/choose-us/2.png" alt="image" />
                  </div>
                  <div className="choose-content">
                    <h3 className="title">Service 2</h3>
                    <p>A description of an effort and why it matters</p>
                    <a href="#0" className="read-more-btn">
                      read more<i className="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
                {/* choose-item end */}
                <div className="choose-item text-center">
                  <div className="choose-thumb">
                    <img src="assets/images/choose-us/3.png" alt="image" />
                  </div>
                  <div className="choose-content">
                    <h3 className="title">Service 3</h3>
                    <p>A description of an effort and why it matters</p>
                    <a href="#0" className="read-more-btn">
                      read more<i className="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
                {/* choose-item end */}
                <div className="choose-item text-center">
                  <div className="choose-thumb">
                    <img src="assets/images/choose-us/4.png" alt="image" />
                  </div>
                  <div className="choose-content">
                    <h3 className="title">Service 4</h3>
                    <p>A description of an effort and why it matters</p>
                    <a href="#0" className="read-more-btn">
                      read more<i className="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
                {/* choose-item end */}
                <div className="choose-item text-center">
                  <div className="choose-thumb">
                    <img src="assets/images/choose-us/5.png" alt="image" />
                  </div>
                  <div className="choose-content">
                    <h3 className="title">Service 5</h3>
                    <p>Get to know opportunities</p>
                    <a href="#0" className="read-more-btn">
                      read more<i className="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
                {/* choose-item end */}
              </div>
            </div>
          </div>
        </div>
      </section>
      ;{/* choose-us-section end */}
      {/* features-section start */}
      {/* features-section end */}
      {/* offer-section start */}
      <section className="offer-section pt-50 pb-120">
        <div className="ball-object">
          <img src="assets/images/elements/offer-ball-object.png" alt="image" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-8">
                  <div className="offer-item">
                    <div className="icon">
                      <img src="assets/images/icons/offer/1.svg" alt="icon" />
                    </div>
                    <div className="content">
                      <h3 className="title">Invest</h3>
                      <p>Best way t o put your idle money to work.</p>
                      <a href="#0" className="read-more-btn">
                        read more<i className="icofont-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  {/* offer-item end */}
                </div>
                <div className="col-lg-8 offset-lg-2">
                  <div className="offer-item">
                    <div className="icon">
                      <img src="assets/images/icons/offer/2.svg" alt="icon" />
                    </div>
                    <div className="content">
                      <h3 className="title">Infobase</h3>
                      <p>Invest without net baning/debit card.</p>
                      <a href="#0" className="read-more-btn">
                        read more<i className="icofont-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  {/* offer-item end */}
                </div>
                <div className="col-lg-8 offset-lg-4">
                  <div className="offer-item">
                    <div className="icon">
                      <img src="assets/images/icons/offer/3.svg" alt="icon" />
                    </div>
                    <div className="content">
                      <h3 className="title">Cap Finder</h3>
                      <p>Grow your saving by investing as little as $5</p>
                      <a href="#0" className="read-more-btn">
                        read more<i className="icofont-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  {/* offer-item end */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-5">
              <div className="offer-wrapper-content">
                <span className="subtitle">
                  Our mission is to help our User
                </span>
                <h2 className="title">To Maximize Money</h2>
                <p>
                  Whether you're new to investing or a seasoned pro, Behoof does
                  what is right for you and your money. On average, our
                  investing principles can increase returns by 15% when compared
                  to the typical investor.
                </p>
                <p>
                  We believe that the future of finance looks very different to
                  how it looks today and we offer qualifying investors the
                  opportunity to invest in the companies and products that are
                  building the future of finance.
                </p>
                <a href="#0" className="btn btn-primary btn-hover">
                  what we offer
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      ;{/* offer-section end */}
      {/* invest-section start */}
      <section className="invest-section pt-120 pb-120">
        <div className="round-shape d-xl-block d-none">
          <img src="assets/images/elements/invest-shape.png" alt="image" />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-header text-center text-white">
                <span className="section-subtitle">
                  The smarter way to invest!
                </span>
                <h2 className="section-title">
                  {Investment && Investment.title}
                </h2>
                <p>{Investment && Investment.description}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="invest-table-area style--two wow fadeInUp"
                data-wow-duration="0.5s"
                data-wow-delay="0.5s"
              >
                <table>
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>Categories</th>
                      <th>Type</th>
                      <th>Description</th>
                      <th>Key Statistics</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <span className="price">
                          General Investment Options
                        </span>
                      </td>
                      <td>
                        <span className="growth">
                          Fixed Deposit Receipts - FDR
                        </span>
                      </td>
                      <td>
                        <span className="daily-dividend">FDR - 3 Months</span>
                      </td>
                      <td>
                        <span className="investors-amount">13% - 20%</span>
                      </td>
                      <td>
                        <a
                          href="#0"
                          className="btn btn-primary btn-round pull-right"
                        >
                          View Now
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>2</td>
                      <td>
                        <span className="price">
                          General Investment Options
                        </span>
                      </td>
                      <td>
                        <span className="growth">
                          Fixed Deposit Receipts - FDR
                        </span>
                      </td>
                      <td>
                        <span className="daily-dividend">FDR - 1 Year</span>
                      </td>
                      <td>
                        <span className="investors-amount">12% - 18%</span>
                      </td>
                      <td>
                        <a
                          href="#0"
                          className="btn btn-primary btn-round pull-right"
                        >
                          View Now
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>3</td>
                      <td>
                        <span className="price">
                          General Investment Options
                        </span>
                      </td>
                      <td>
                        <span className="growth">Government Securities</span>
                      </td>
                      <td>
                        <span className="daily-dividend">
                          National Savings Certificates
                        </span>
                      </td>
                      <td>
                        <span className="investors-amount">10% - 15%</span>
                      </td>
                      <td>
                        <a
                          href="#0"
                          className="btn btn-primary btn-round pull-right"
                        >
                          View Now
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="btn-area mt-50 text-center">
                <a href="#0" className="btn btn-primary btn-hover text-small">
                  Browse Onu Invest
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* invest-section end */}
      {/* calculate-profit-section start */}
      {/* calculate-profit-section end */}
      {/* deposit-withdraw-section start */}
      {/* deposit-withdraw-section end */}
      {/* investors-section start */}
      {/* investors-section end */}
      {/* affiliate-features-section start */}
      {/* affiliate-features-section end */}
    </div>
  );
};

export default Main;
