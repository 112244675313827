
import React, { useState, useEffect, useContext } from 'react'
import "../admin/Admin.css"

import { Formik } from "formik";
import * as Yup from "yup";
import CapFinderModal from '../views/CapFinderModal';

import AuthContext from "../../AuthContext";
import { firebase } from "../../firebase";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';


const CapitalGate = () => {

    const [showModal, setshowModal] = useState(false);

    const [showAlert, setshowAlert] = useState(false)

    const [isAuthenticated, setisAuthorized] = useState(false);

    const userData = useContext(AuthContext)

    useEffect(() => {
        if (userData.user) {
            setisAuthorized(true)
        }
    }, [userData])

    const dbCon = firebase.database();

    const CapInfoHaandler = (UserName, FirmType, BusinessSize, CapitalType, CapitalSize) => {
        dbCon.ref("CapitalGateReq").child(userData.user).set({
            UserName, FirmType, BusinessSize, CapitalType, CapitalSize
        }, (error) => {
            console.log(error)
        }).then(() => { setshowAlert(true) })
    }


    if (showModal) {
        return <CapFinderModal show={showModal} onHide={() => setshowModal(false)} />
    }



    return (
        <div>
            <section
                className="inner-page-banner-section gradient-bg"
                style={{ paddingTop: "165px", paddingBottom: "55px" }}
            >
                <h2 className="CapgateHeader">Capital Gate</h2>
                <p className="Capgatepromo">Finding capital is not really that difficult, if you know where and whom to look for</p>
            </section>
            <section className="container CapMainArea">
                <div className="row">
                    <p className="CapInfo1">Please follow the instruction to enlist your capital requirement in our site. Once completed, it will undergo a review, and then will be uploaded within 1 business day.
                        For incomplete or erroneous inputs, we would revert the request and you'll be able to update till finalized - within a maximum three days of your request. after three days, all unprocessed requests are automatically deleted from system, and you may need to do a fresh request in such case.</p>

                </div>
                <div className="row">
                    <div className="col-md-8">
                        <h4 className="CapFormHeading">Capital Finding Enlistment Form</h4>

                        <Formik
                            initialValues={{
                                name: '',
                                FirmType: '',
                                BusinessSize: '',
                                CapitalSize: 0,
                                CapitalType: ''
                            }}
                            onSubmit={(values, action) => {
                                CapInfoHaandler(values.name, values.FirmType, values.BusinessSize, values.CapitalSize, values.CapitalType)
                                action.resetForm()
                            }}
                        >
                            {({ values, handleBlur, handleChange, handleSubmit }) => (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="name">Your Name*</label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="Provide Your Name"
                                                onBlur={handleBlur}
                                                value={values.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="FirmType">Firm Type</label>
                                            <select
                                                name="FirmType"
                                                className="CapDropdownSelect"
                                                value={values.FirmType}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ display: "block" }}
                                            >
                                                <option value="" label="---Select---" disabled />
                                                <option value="Sole Proprietorship" label="Sole Proprietorship" />
                                                <option value="Partnership" label="Partnership" />
                                                <option value="Public Limited Company" label="Public Limited Company" />
                                                <option value="Private Limited Company" label="Private Limited Company" />
                                                <option value="Other" label="Other" />
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="BusinessSize">Define your business size *</label>
                                            <select
                                                name="BusinessSize"
                                                className="CapDropdownSelect"
                                                value={values.BusinessSize}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ display: "block" }}
                                            >
                                                <option value="" label="---Select---" disabled />
                                                <option value="CRP" label="Crowdfunding Project [Looking to raise money through crowdfunding]" />
                                                <option value="Startup" label="Start Up [Pre seed] - Product or service not yet fully ready, need capital for development cost" />
                                                <option value="Startups" label="Start Up [Seed] - Product/Service Ready, Need capital for rollout cost/development/expansion support" />
                                                <option value="Startupps" label="Start Up [Post Seed] - Revenue earning business, need capital for expansion/management" />
                                                <option value="SMB" label="Small Business - Monthly revenue < BDT 10 Lacs" />
                                                <option value="MB" label="Medium Business - Monthly revenue < BDT 75 Lacs" />
                                                <option value="LGC" label="Large Corporate - Annual Revenue > BDT 10 Crore" />
                                                <option value="GPB" label="Group of Business - Annual revenue > BDT 100 Crore" />
                                                <option value="BH" label="Business House - Annual revenue > BDT 1000 Crore" />
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="CapitalSize">How Much Capital You are looking for *</label>
                                            <input
                                                type="number"
                                                name="CapitalSize"
                                                className="form-control"
                                                placeholder="Write in BDT Amount"
                                                onBlur={handleBlur}
                                                value={values.CapitalSize}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="CapitalType">What Type of Capital You are looking for? *</label>
                                            <select
                                                name="CapitalType"
                                                className="CapDropdownSelect"
                                                value={values.CapitalType}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ display: "block" }}
                                            >
                                                <option value="" label="---Select---" disabled />
                                                <option value="Loan" label="Loan" />
                                                <option value="Equity" label="Equity" />
                                                <option value="Both" label="Both" />

                                            </select>
                                        </div>
                                        <div className="form-group Capbutton">
                                            {isAuthenticated ? <button
                                                type="submit"
                                                className="btn btn-primary btn-hover"
                                            >
                                                Submit
                                            </button> :
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Please login first!</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <button className="btn btn-primary" disabled style={{ pointerEvents: 'none' }}>
                                                            Submit
                                                        </button>
                                                    </span>
                                                </OverlayTrigger>
                                            }
                                            <Alert show={showAlert} variant="success">
                                                <Alert.Heading>Onucrom Team!</Alert.Heading>
                                                <p>
                                                    Your Request is submitted. Thank you for your interest. We will get back to you soon.
                                                </p>
                                                <hr />
                                                <div className="d-flex justify-content-end">
                                                    <button onClick={() => setshowAlert(false)} variant="outline-success">
                                                        Close
                                                    </button>
                                                </div>
                                            </Alert>
                                        </div>
                                    </form>

                                </>
                            )}
                        </Formik>



                    </div>

                    <div className="col-md-4 CapAdditionalMainSec">
                        <div className="vl"></div>

                        <h4>Additional Services</h4>

                        <div className="capAdditionalSec">
                            <button type="submit" className="btn btn-info btn-hover" onClick={() => { setshowModal(true) }}>Arranger Services</button>
                            <button className="btn btn-info btn-hover" onClick={() => { setshowModal(true) }}>Alternative Solutions</button>
                            <button className="btn btn-info btn-hover" onClick={() => { setshowModal(true) }}>Feasibility Assessment</button>
                            <button className="btn btn-info btn-hover" onClick={() => { setshowModal(true) }}>Structuring Support</button>
                            <button className="btn btn-info btn-hover" onClick={() => { setshowModal(true) }}>Due Diligence Processing</button>
                            <button className="btn btn-info btn-hover" onClick={() => { setshowModal(true) }}>Financial Modeling</button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 CapInfo2">
                        <div className="col-md-6">
                            <p>Our Information Services</p>
                            <ul>
                                <li>We would make your requirement visible to interested investors.</li>
                                <li>Communicate to investors with the information you've provided</li>
                                <li>If someone is interested, we'll introduce them to you.</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <p>Charges:</p>
                            <ul>
                                <li>Public AD: Free !	[Best if you want everybody to know and bid for the requirement].</li>
                                <li>Confidential Posting: BDT 1000/14 Days [Best when you'd like to keep competitor and certain other groups unaware of your plans] <span>Includes bridging services</span></li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="hl"></div>
                <div className="row CapInfo3">

                    <p>Important</p>
                    <ul>
                        <li>Any information marked as confidential by you would be shared with your permission only.</li>
                        <li>Violation of our terms would eliminate your membership, without any notice. This includes sharing any information sourced from our website, without explicit permission from us.</li>

                    </ul>
                </div>


            </section>

        </div>
    )
}

export default CapitalGate
