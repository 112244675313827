import React from 'react'

const Payments = () => {
    return (
        <div>
            <div className="row mb-none-30" style={{ paddingTop: "2%" }}>
                <div className="col-md-12">
                    <div
                        className="blog-item blog-list mb-30 useractivityTitle"
                        style={{ padding: "10px 5px 10px 15px" }}
                    >
                        <h3
                            style={{
                                color: "white",
                                fontWeight: "bold",
                            }}
                        >
                            Payment Log
                        </h3>
                    </div>
                </div>
                {/* blog-item end */}
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div
                        className="invest-table-area wow fadeInUp"
                        data-wow-duration="0.5s"
                        data-wow-delay="0.5s"
                    >
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>File Name</th>
                                    <th>User</th>
                                    <th>Total Amount</th>
                                    <th>Paid Amount</th>
                                    <th>Due Amount</th>
                                    <th>Receipt</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td> <div className="Capbutton">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-hover"
                                        >
                                            Print
                                        </button>
                                    </div></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*  <div className="btn-area mt-50 text-center">
            <a href="#0" className="btn btn-primary btn-hover text-small">
              browse more
            </a>
          </div> */}
                </div>
            </div>


        </div>
    )
}

export default Payments
