import React, { useState, useEffect } from 'react'
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaMixer } from "react-icons/fa";

import { firebase } from "../../firebase";
import { Link, useHistory } from 'react-router-dom';
import "./onuInvest.css"

const fireDb = firebase.database();

const SidebarComponent = () => {
    const [isCollapsed, setisCollapsed] = useState(false);
    const [headerClass, setheaderClass] = useState([]);
    const [subheaderClass, setsubheaderClass] = useState([]);
    const [subheaderClass2, setsubheaderClass2] = useState([])

    const loadHeader = () => {
        fireDb.ref("OnuCrom").child("HeaderClass").once('value', (snapshot) => {

            let tempHeaderClass = []
            snapshot.forEach(snap => {
                tempHeaderClass.push(snap.val().name)

            });
            setheaderClass(tempHeaderClass)
        })
    }

    const loadSubHeading = (val) => {
        fireDb.ref("OnuCrom").child(`SubHeading/${val}`).once('value', (snapshot) => {

            let tempHeaderClass = []
            snapshot.forEach(snap => {
                tempHeaderClass.push(snap.val().name)

            });
            setsubheaderClass(tempHeaderClass)
        })
    }

    const loadSubHeading2 = (val, val2) => {
        fireDb.ref("OnuCrom").child(`SubHeading2/${val}/${val2}`).once('value', (snapshot) => {

            let tempHeaderClass = []
            snapshot.forEach(snap => {
                tempHeaderClass.push(snap.val().name)

            });
            setsubheaderClass2(tempHeaderClass)
        })
    }

    useEffect(() => {
        loadHeader()
    }, [])

    const history = useHistory()
    const loadClassInfo = (val3) => {
        history.replace(`detailsbyClassName/${val3}`)
    }
    return (
        <ProSidebar collapsed={isCollapsed}>
            <SidebarHeader>
                <div
                    style={{
                        padding: '24px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        fontSize: 14,
                        letterSpacing: '1px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: "flex",
                        justifyContent: 'space-between',
                        cursor: 'pointer'
                    }}
                    onClick={() => { setisCollapsed(!isCollapsed) }}
                >
                    MENU
                    <FaMixer />
                </div>
            </SidebarHeader>
            <SidebarContent>
                {
                    headerClass.map((val) => {
                        return (
                            <Menu iconShape="square">

                                <SubMenu title={val} onClick={() => {
                                    loadSubHeading(val)
                                }}>
                                    {
                                        subheaderClass.map((val2) => {
                                            return (
                                                <SubMenu title={val2} onClick={() => { loadSubHeading2(val, val2) }}>
                                                    {
                                                        subheaderClass2.map((val3) => {
                                                            return (
                                                                <MenuItem onClick={() => { loadClassInfo(val3) }}>{val3}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </SubMenu>
                                            )
                                        })
                                    }

                                </SubMenu>
                            </Menu>
                        )
                    })
                }

            </SidebarContent>
            <SidebarFooter>
                {/**
     *  You can add a footer for the sidebar ex: copyright
     */}
            </SidebarFooter>

        </ProSidebar>
    )
}

export default SidebarComponent
