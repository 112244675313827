import React, { useState } from "react";
import "../admin/Admin.css";

import { Table } from "react-bootstrap";

import { useHistory } from "react-router-dom";

const SpecificClass = () => {
    const [showTable, setshowTable] = useState(false);
    const [option, setoption] = useState("");

    const history = useHistory();
    return (
        <div>
            <section
                className="inner-page-banner-section gradient-bg"
                style={{ paddingTop: "115px", paddingBottom: "100px" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xl-12">
                            <div className="SpecificClassHeader">
                                <h4>Selected file Information will be Displayed Here</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-xl-3">
                            <div className="SideBarContainer">
                                <h4>Onu Invest</h4>
                                <ul>
                                    <li>Option 1</li>
                                    <li>Option 2</li>
                                    <li>Option 3</li>
                                    <li>Option 4</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xl-9">
                            <div className="MainAreaContainer">
                                <h4>Uploaded Excel File and Download Link</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ClassSchema1">
                {/*  <form >
                    <div className="form-group">
                        <label htmlFor="">Choose Header Class</label>
                        <select
                            name="HeaderClass"
                            className="CapDropdownSelect"
                            value={option}
                            onChange={(e) => { setoption(e.target.value) }}
                            style={{ display: "block" }}
                            required
                        >
                            <option value="" label="---Select---" disabled />
                            <option value="Share" label="Share" />
                            <option value="Industry" label="Industry" />
                            <option value="MutualF" label="Mutual Funds" />
                            <option value="Bonds" label="Bonds" />

                        </select>
                    </div>



                    <div className="form-group Capbutton">
                        <button
                            type="button"
                            className="btn btn-primary btn-hover"
                            onClick={() => { setshowTable(true); history.push(`/spesificClassDetails/${option}`) }}
                        >
                            Load Data
                        </button>
                    </div>

                </form> */}
                <hr />
                <div>
                    {showTable && (
                        <Table striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th>Filename</th>
                                    <th>Class</th>
                                    <th>Subject</th>
                                    <th>Author</th>
                                    <th>Brief Paragraph</th>

                                    <th>Upload Date</th>
                                    <th>Price</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    )}
                </div>
            </section>
        </div>
    );
};

export default SpecificClass;
