
import React, { useState, useContext, useEffect } from "react";

import { Formik } from "formik";
import AuthContext from "../../AuthContext";
import { firebase } from "../../firebase";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as XLSX from "xlsx";
import "./onuInvest.css";
import { useHistory, useParams } from "react-router-dom"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const fireDb = firebase.database();

const EditContent = () => {

    const [isNotification, setisNotification] = useState(false);
    const [headerClass, setheaderClass] = useState("");
    const [SubHeading, setSubHeading] = useState("")
    const [Subheader2, setSubheader2] = useState("")
    const [author, setauthor] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [summary, setSummary] = useState("");
    const [disclaimer, setDisclaimer] = useState("");
    const [loadedData, setloadedData] = useState(null)
    const [header, setheader] = useState([])
    const [fileData, setfileData] = useState(null)

    const [isexcelFile, setIsexcelFile] = useState(false);
    const [isloading, setIsloading] = useState(true)

    const { id } = useParams()
    const history = useHistory()
    useEffect(() => {
        fireDb.ref("OnuCrom").child(`SpecificClassDetails/${id}`).once('value', snapshot => {
            setheaderClass(snapshot.val().HeaderClass)
            setSubHeading(snapshot.val().SubHeaderClass)
            setSubheader2(snapshot.val().SubheaderClass2)
            setauthor(snapshot.val().Author)
            setDescription(snapshot.val().SectionDescription)
            setPrice(snapshot.val().price)
            setSummary(snapshot.val().SectionSummary)
            setloadedData(snapshot.val().ExcelData)
            setDisclaimer(snapshot.val().DisclaimerNotes)

            setheader(snapshot.val().ExcelData[0])
            setfileData(snapshot.val().ExcelData.slice(1,))

        }).then(() => { setIsloading(false) })
    }, [id]);

    if (isloading) {
        return <h4>loading</h4>
    }

    /* View excel File */
    const loadData = (event) => {
        let selectedFile = event.target.files[0];
        var reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);

        reader.onload = function (event) {
            var ArrayData = new Uint8Array(event.target.result);


            const workbook = XLSX.read(ArrayData, { type: 'array' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            setloadedData(data)


            if (data.length > 0) {
                var table_output = '<table class="table table-striped table-bordered">';

                for (var row = 0; row < data.length; row++) {
                    table_output += '<tr>';
                    for (var cell = 0; cell < data[row].length; cell++) {
                        table_output += '<td>' + data[row][cell] + '</td>';
                    }

                    table_output += '</tr>';
                }

                table_output += '</table>';


                document.getElementById('excel_data').innerHTML = table_output;
            }
        }


    }

    const notify = () => toast("Wow so easy!");

    if (isNotification) {
        <div>
            <button onClick={notify}>Onucrom!</button>
            <ToastContainer />
        </div>
    }


    /* upload Post */
    const fileUploadHandler = (HeaderClass, SubHeaderClass, SubheaderClass2, SectionDescription, SectionSummary, DisclaimerNotes, price, Author) => {
        let FileCreationDate = new Date();
        setisNotification(true)

        fireDb.ref("OnuCrom").child(`SpecificClassDetails/${id}`).set({
            HeaderClass,
            SubHeaderClass,
            SubheaderClass2,
            Author,
            SectionDescription,
            SectionSummary,
            DisclaimerNotes,
            ExcelData: loadedData,
            price,
            uploadDate: FileCreationDate.toLocaleDateString(),
            uploadTime: FileCreationDate.getHours() + '-' + FileCreationDate.getMinutes() + '-' + FileCreationDate.getSeconds()

        }).then(() => {

            fireDb.ref("OnuCrom").child("SpecificClassDetails2").child(SubheaderClass2).set({
                HeaderClass,
                SubHeaderClass,
                SubheaderClass2,
                Author,
                SectionDescription,
                SectionSummary,
                DisclaimerNotes,

                ExcelData: loadedData,
                price,
                uploadDate: FileCreationDate.toLocaleDateString(),
                uploadTime: FileCreationDate.getHours() + '-' + FileCreationDate.getMinutes() + '-' + FileCreationDate.getSeconds()

            }).then(() => { setisNotification(false); history.push('/listDetails') })

        })
    }

    return <section
        className="inner-page-banner-section gradient-bg"
        style={{ paddingTop: "90px", paddingBottom: "100px", paddingLeft: '50px', paddingRight: '50px' }}
    >
        <Formik
            initialValues={{
                HeaderClass: headerClass,
                subheaderClass: SubHeading,
                subHeader2: Subheader2,
                SectionDescription: description,
                Author: author,
                SectionSummary: summary,
                DisclaimerNotes: disclaimer,
                excelFile: null,
                price: price,
            }}

            onSubmit={(values, action) => {
                fileUploadHandler(values.HeaderClass, values.subheaderClass, values.subHeader2, values.SectionDescription, values.SectionSummary, values.DisclaimerNotes, values.price, values.Author)
                action.resetForm()
            }}

        >
            {({ values, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                <div className="EditSectionContainer">
                    <h6><b>{headerClass} {'>'} {SubHeading} {'>'} {Subheader2}</b><span><button className="btn btn-primary" onClick={() => history.push("/listDetails")}>Back</button></span></h6>
                    <hr />
                    <form onSubmit={handleSubmit}>

                        <div className="form-group">
                            <label htmlFor="HeaderClass">Header Class</label>
                            <input
                                type="text"
                                name="HeaderClass"
                                className="form-control"

                                onBlur={handleBlur}
                                value={values.HeaderClass}
                                onChange={handleChange}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="subheaderClass">Sub Header Class 1</label>
                            <input
                                type="text"
                                name="subheaderClass"
                                className="form-control"

                                onBlur={handleBlur}
                                value={values.subheaderClass}
                                onChange={handleChange}
                                required
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="subHeader2">Sub Header Class 2</label>
                            <input
                                type="text"
                                name="subHeader2"
                                className="form-control"

                                onBlur={handleBlur}
                                value={values.subHeader2}
                                onChange={handleChange}
                                required
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="Author">Editor*</label>
                            <input
                                type="text"
                                name="Author"
                                className="form-control"
                                placeholder="Author Name"
                                onBlur={handleBlur}
                                value={values.Author}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group" >
                            <label htmlFor="SectionDescription">Write Section Description</label>
                            <textarea
                                type="number"
                                name="SectionDescription"
                                className="form-control"
                                placeholder="Provide short description"
                                onBlur={handleBlur}
                                value={values.SectionDescription}
                                onChange={handleChange}
                                rows="3"
                            ></textarea>
                        </div>

                        <div className="form-group">
                            <label htmlFor="price">Key Statistics*</label>
                            <input
                                type="number"
                                name="price"
                                className="form-control"
                                placeholder="What is the price"
                                onBlur={handleBlur}
                                value={values.price}
                                onChange={handleChange}
                                required
                            />
                        </div>



                        <div className="form-group" style={{ height: "500px" }}>
                            <label htmlFor="Brief">Write Section Summary</label>
                            <ReactQuill style={{ height: "430px" }} className="editor" name="SectionSummary" value={values.SectionSummary} theme="snow"
                                onChange={(e) => { setFieldValue("SectionSummary", e) }} modules={modules} format={format} />

                        </div>

                        <div className="form-group" style={{ height: "300px" }}>
                            <label htmlFor="Brief">Write Disclaimer Notes</label>
                            <ReactQuill style={{ height: "240px" }} className="editor" name="DisclaimerNotes" value={values.DisclaimerNotes} theme="snow"
                                onChange={(e) => { setFieldValue("DisclaimerNotes", e) }} modules={modules} format={format} />

                        </div>
                        <div className="form-group">
                            <label htmlFor="Brief">Upload Excel File</label>
                            <input type="file" name="excelFile" value={undefined} className="excel_file" onChange={(e) => { setIsexcelFile(true); setFieldValue("excelFile", e.target.files[0]); loadData(e) }} />

                        </div>
                        {
                            isexcelFile ? <div id="excel_data"></div> :
                                <div className='tableContainer'>
                                    <table className="table table-striped table-bordered">
                                        <thead className="thead-dark">
                                            <tr>
                                                {

                                                    header.map((value) => {

                                                        return (
                                                            <th>
                                                                {value}
                                                            </th>
                                                        )
                                                    })

                                                }


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {


                                                fileData.map((val, index) => {

                                                    return (
                                                        <tr>
                                                            {val.map((dt, id) => {
                                                                return (
                                                                    <td>{dt}</td>
                                                                )
                                                            })}
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>

                                    </table>
                                </div>
                        }

                        {/* load table starts 2  */}

                        {/* load table ends */}
                        <div className="form-group Capbutton">
                            <button
                                type="submit"
                                className="btn btn-primary btn-hover"
                            >
                                Update
                            </button>
                        </div>
                    </form>

                </div>


            )}
        </Formik>
    </section>

};


const modules = {
    toolbar: [
        [{ font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline",],
        [{ header: "1" }, { header: "2" }, { header: "3" }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ["clean"]
    ],
}

const format = [

    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'header',
    'list',
    'bullet'

]

export default EditContent;
