import React, { useContext, useEffect, useState } from 'react'
import "../admin/Admin.css";

import AuthContext from "../../AuthContext";
import { firebase } from "../../firebase";

import * as XLSX from "xlsx"


const InfoBase = () => {
    const [ShareData, setShareData] = useState({})
    const [IndustryData, setIndustryData] = useState({})
    const userData = useContext(AuthContext)


    const ReadExcelData = (file) => {
        const fileData = new Promise((res, rej) => {
            const fileRedears = new FileReader();
            fileRedears.readAsArrayBuffer(file);

            fileRedears.onload = () => {
                const bufferArray = file;

                const wb = XLSX.read(bufferArray, { type: "buffer" })

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                res(data)


            }

            fileRedears.onerror = (error) => {
                rej(error)
            }
        })

        fileData.then(d => {
            console.log(d)
        })
    }

    const LoadShareData = () => {
        firebase.database().ref("UploadFiles").child(userData.user).child("Share").on('value', snapshot => {
            if (snapshot.val() != null) {
                console.log(snapshot.val())
                setShareData({
                    ...snapshot.val()
                })
            }

        })
    }

    const LoadIndustryData = () => {
        firebase.database().ref("UploadFiles").child(userData.user).child("Industry").on('value', snapshot => {
            if (snapshot.val() != null) {
                console.log(snapshot.val())
                setIndustryData({
                    ...snapshot.val()
                })
            }

        })
    }
    /* const userInfo = new Promise((res, rej) => {
        if (userData) {
            res(userData.user)
        }
        else {
            rej(new Error("No user Found"))
        }
    })

    userInfo.then((res) => {
        LoadShareData(res)
    }).catch((error) => { console.log(error) }) */






    return (
        <div>
            <section
                className="inner-page-banner-section gradient-bg"
                style={{ paddingTop: "165px", paddingBottom: "55px" }}
            >
                <h2 className="CapgateHeader">InfoBase</h2>
                <p className="Capgatepromo">Welcome to the Information Warehouse</p>
            </section>

            {/* Industry */}
            <div className="row mb-none-30 infobaseDesign1">
                <div className="col-md-12">
                    <div
                        className="blog-item blog-list mb-30 useractivityTitle2"
                        style={{ padding: "10px 30px 0px 30px", display: 'flex', justifyContent: 'space-between' }}
                    >
                        <h3
                            style={{
                                color: "white",
                                fontWeight: "bold",
                            }}
                        >
                            Industry
                        </h3>
                        <div className="form-group Capbutton">
                            <button
                                type="submit"
                                className="btn btn-primary btn-hover"
                                onClick={LoadIndustryData}
                            >
                                Load Data
                            </button>
                        </div>
                    </div>
                </div>
                {/* blog-item end */}
            </div>
            <div className="row infobaseDesign2" >
                <div className="col-lg-12">
                    <div
                        className="invest-table-area wow fadeInUp"
                        data-wow-duration="0.5s"
                        data-wow-delay="0.5s"
                    >
                        <table>
                            <thead>
                                <tr>

                                    <th>Filename</th>
                                    <th>Class</th>
                                    <th>Subject</th>
                                    <th>Author</th>
                                    <th>Brief Paragraph</th>

                                    <th>Upload Date</th>
                                    <th>Price</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(IndustryData).map(id => {
                                        return <tr>
                                            <td>
                                                <span className="price"><a href="">{IndustryData[id].FileName}</a></span>
                                            </td>
                                            <td>
                                                <span className="name">{IndustryData[id].Class}</span>
                                            </td>

                                            <td>
                                                <span className="daily-dividend">{IndustryData[id].Subject}</span>
                                            </td>
                                            <td>
                                                <span className="investors-amount">{IndustryData[id].Author}</span>
                                            </td>
                                            <td>
                                                <span className="investors-amount">{IndustryData[id].Brief}</span>
                                            </td>
                                            <td>
                                                <span className="investors-amount">Date</span>
                                            </td>
                                            <td>
                                                <span className="investors-amount">{IndustryData[id].price}</span>
                                            </td>
                                            <td>
                                                <span className="investors-amount"><div className="form-group Capbutton">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-hover"
                                                    >
                                                        Download
                                                    </button>
                                                </div></span>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {/*  <div className="btn-area mt-50 text-center">
            <a href="#0" className="btn btn-primary btn-hover text-small">
              browse more
            </a>
          </div> */}
                </div>
            </div>


            {/* Share */}
            <div className="row mb-none-30 infobaseDesign1">
                <div className="col-md-12">
                    <div
                        className="blog-item blog-list mb-30 useractivityTitle"
                        style={{ padding: "10px 30px 0px 30px", display: 'flex', justifyContent: 'space-between' }}
                    >
                        <h3
                            style={{
                                color: "white",
                                fontWeight: "bold",
                            }}
                        >
                            Share
                        </h3>
                        <div className="form-group Capbutton">
                            <button
                                type="submit"
                                className="btn btn-primary btn-hover"
                                onClick={LoadShareData}
                            >
                                Load Data
                            </button>
                        </div>
                    </div>

                </div>

                {/* blog-item end */}
            </div>
            <div className="row infobaseDesign2" >
                <div className="col-lg-12">
                    <div
                        className="invest-table-area wow fadeInUp"
                        data-wow-duration="0.5s"
                        data-wow-delay="0.5s"
                    >
                        <table>
                            <thead>
                                <tr>

                                    <th>Filename</th>
                                    <th>Class</th>
                                    <th>Subject</th>
                                    <th>Author</th>
                                    <th>Brief Paragraph</th>

                                    <th>Upload Date</th>
                                    <th>Price</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(ShareData).map(id => {
                                        return <tr>
                                            <td>
                                                <span className="price">{ShareData[id].FileName}</span>
                                            </td>
                                            <td>
                                                <span className="name">{ShareData[id].Author}</span>
                                            </td>

                                            <td>
                                                <span className="daily-dividend"></span>
                                            </td>
                                            <td>
                                                <span className="investors-amount"></span>
                                            </td>
                                            <td>
                                                <span className="investors-amount"></span>
                                            </td>
                                            <td>
                                                <span className="investors-amount"></span>
                                            </td>
                                            <td>
                                                <span className="investors-amount"></span>
                                            </td>
                                            <td>
                                                <span className="investors-amount"><div className="form-group Capbutton">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-hover"
                                                    >
                                                        Download
                                                    </button>
                                                </div></span>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {/*  <div className="btn-area mt-50 text-center">
            <a href="#0" className="btn btn-primary btn-hover text-small">
              browse more
            </a>
          </div> */}
                </div>
            </div>

            {/* Mutual Funds */}
            <div className="row mb-none-30 infobaseDesign1">
                <div className="col-md-12">
                    <div
                        className="blog-item blog-list mb-30 useractivityTitle2"
                        style={{ padding: "10px 5px 10px 15px" }}
                    >
                        <h3
                            style={{
                                color: "white",
                                fontWeight: "bold",
                            }}
                        >
                            Mutual Funds
                        </h3>
                    </div>
                </div>
                {/* blog-item end */}
            </div>
            <div className="row infobaseDesign2" >
                <div className="col-lg-12">
                    <div
                        className="invest-table-area wow fadeInUp"
                        data-wow-duration="0.5s"
                        data-wow-delay="0.5s"
                    >
                        <table>
                            <thead>
                                <tr>

                                    <th>Filename</th>
                                    <th>Class</th>
                                    <th>Subject</th>
                                    <th>Author</th>
                                    <th>Brief Paragraph</th>

                                    <th>Upload Date</th>
                                    <th>Price</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="price"></span>
                                    </td>
                                    <td>
                                        <span className="name"></span>
                                    </td>

                                    <td>
                                        <span className="daily-dividend"></span>
                                    </td>
                                    <td>
                                        <span className="investors-amount"></span>
                                    </td>
                                    <td>
                                        <span className="investors-amount"></span>
                                    </td>
                                    <td>
                                        <span className="investors-amount"></span>
                                    </td>
                                    <td>
                                        <span className="investors-amount"></span>
                                    </td>
                                    <td>
                                        <span className="investors-amount"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*  <div className="btn-area mt-50 text-center">
            <a href="#0" className="btn btn-primary btn-hover text-small">
              browse more
            </a>
          </div> */}
                </div>
            </div>

            {/* Bonds */}
            <div className="row mb-none-30 infobaseDesign1">
                <div className="col-md-12">
                    <div
                        className="blog-item blog-list mb-30 useractivityTitle"
                        style={{ padding: "10px 5px 10px 15px" }}
                    >
                        <h3
                            style={{
                                color: "white",
                                fontWeight: "bold",
                            }}
                        >
                            Bonds
                        </h3>
                    </div>
                </div>
                {/* blog-item end */}
            </div>
            <div className="row infobaseDesign2" >
                <div className="col-lg-12">
                    <div
                        className="invest-table-area wow fadeInUp"
                        data-wow-duration="0.5s"
                        data-wow-delay="0.5s"
                    >
                        <table>
                            <thead>
                                <tr>

                                    <th>Filename</th>
                                    <th>Class</th>
                                    <th>Subject</th>
                                    <th>Author</th>
                                    <th>Brief Paragraph</th>

                                    <th>Upload Date</th>
                                    <th>Price</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="price"></span>
                                    </td>
                                    <td>
                                        <span className="name"></span>
                                    </td>

                                    <td>
                                        <span className="daily-dividend"></span>
                                    </td>
                                    <td>
                                        <span className="investors-amount"></span>
                                    </td>
                                    <td>
                                        <span className="investors-amount"></span>
                                    </td>
                                    <td>
                                        <span className="investors-amount"></span>
                                    </td>
                                    <td>
                                        <span className="investors-amount"></span>
                                    </td>
                                    <td>
                                        <span className="investors-amount"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*  <div className="btn-area mt-50 text-center">
            <a href="#0" className="btn btn-primary btn-hover text-small">
              browse more
            </a>
          </div> */}
                </div>
            </div>



        </div>
    )
}

export default InfoBase
