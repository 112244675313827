import React, { useState, useEffect } from 'react'
import "./onuInvest.css";
import { firebase } from "../../firebase";
import { FaAngleDown } from "react-icons/fa";

import parse from 'html-react-parser';


const fireDb = firebase.database();

const DisplayContent = (props) => {
    const [data, setdata] = useState({})
    const [header, setheader] = useState([])
    const [fileData, setfileData] = useState(null)
    const [isLoading, setisLoading] = useState(true)
    const [showDescription, setshowDescription] = useState(true)

    const loadDatafromDb = () => {
        if (props.link != null) {
            fireDb.ref("OnuCrom").child(`SpecificClassDetails2/${props.link}`).once('value', (snapshot) => {

                setdata(snapshot.val())
                setheader(snapshot.val().ExcelData[0])
                setfileData(snapshot.val().ExcelData.slice(1,))
                console.log(snapshot.val())

            }).then(() => { setisLoading(false) })
        }
        else {
            fireDb.ref("OnuCrom").child(`OnuInvestData/${props.id}`).once('value', (snapshot) => {

                setdata(snapshot.val())
                setheader(snapshot.val().ExcelData[0])
                setfileData(snapshot.val().ExcelData.slice(1,))

            }).then(() => { setisLoading(false) })
        }

    }



    useEffect(() => {
        loadDatafromDb()

    }, [props.id, props.link])

    console.log(props.id, props.link)
    if (isLoading) {
        return (
            <div>
                Loading......
            </div>
        )
    }
    return (
        <div className='displayContentSectionContainer'>
            {/* Section 1  */}
            <div className='headerInfo'>
                <h3>{data.SubheaderClass2} <span onClick={() => { setshowDescription(!showDescription) }}><FaAngleDown /></span></h3>
                <hr />
                {showDescription ? <p>{data.SectionDescription}</p> : ""}
            </div>
            {/* Section 2  */}
            <div className='tableContainer unselectable'>
                <table className="table table-striped table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            {

                                header != null ? header.map((value) => {

                                    return (
                                        <th>
                                            {value}
                                        </th>
                                    )
                                }) : ""

                            }


                        </tr>
                    </thead>
                    <tbody>
                        {


                            fileData != null ? fileData.map((val, index) => {

                                return (
                                    <tr>
                                        {val.map((dt, id) => {
                                            return (
                                                <td>{dt}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            }) : ""
                        }
                    </tbody>

                </table>
            </div>

            {/* Section 3  */}

            <div className='summaryContainer'>
                <h4>Summary </h4>
                <hr />
                {parse(data.SectionSummary)}
            </div>
            {/* Section 4  */}
            <div className='disclaimerContainer'>
                <h4>Disclaimer</h4>
                <hr />
                {parse(data.DisclaimerNotes)}
            </div>

        </div>
    )
}

export default DisplayContent
