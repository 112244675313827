import React from "react";

const Footer = () => {
  return (
    <div>
      {/*footer-section start */}
      <footer className="footer-section">
        <div
          className="footer-top bg_img"
          data-background="assets/images/footer-bg-3.png"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div
                  className="section-header text-white text-center"
                  style={{ marginBottom: "25px" }}
                >
                  <span className="section-subtitle">
                    Try Today! <b>It's Free</b>
                  </span>
                  <h2 className="section-title">To Get Exclusive Benefits</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="subscribe-wrapper style--two">
                  <form className="subscribe-form">
                    <button type="submit" className="subs-btn">
                      Sign Up
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <img
                  src="assets/images/Site Logo 2021 V1 - web 200 x 80.png"
                  alt="site-logo"
                  style={{ paddingTop: "5px", height: "70px" }}
                />
                <p
                  style={{
                    color: "white",
                    textAlign: "justify",
                    paddingTop: "10px",
                    fontSize: "14px",
                  }}
                >
                  Onucrom is a investment genertion website where users can
                  safely invest and earn profit
                </p>
              </div>
              {/*footer-widget end */}
              <div className="col-md-3">
                <div className="footer-widget">
                  <h3 className="widget-title">About</h3>
                  <ul className="footer-menu-list">
                    <li>
                      <a href="#0">About us</a>
                    </li>
                    <li>
                      <a href="#0">Contact Us</a>
                    </li>
                    <li>
                      <a href="#0">Latest Updates</a>
                    </li>

                    <li>
                      <a href="#0">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer-widget">
                  <h3 className="widget-title">Services</h3>
                  <ul className="footer-menu-list">
                    <li>
                      <a href="#0">Onu Invest</a>
                    </li>
                    <li>
                      <a href="#0">InfoBase</a>
                    </li>
                    <li>
                      <a href="#0">Capital Finder</a>
                    </li>
                    <li>
                      <a href="#0">Manage Your Account</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/*footer-widget end */}
              <div className="col-md-3">
                <div className="footer-widget">
                  <h3 className="widget-title">Help center</h3>
                  <ul className="footer-menu-list">
                    <li>
                      <a href="#0">FAQ</a>
                    </li>
                    <li>
                      <a href="#0">Class Guide</a>
                    </li>
                    <li>
                      <a href="#0">Legal</a>
                    </li>
                    <li>
                      <a href="#0">QTerms</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/*footer-widget end */}

              {/*footer-widget end */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <p className="copy-right-text">
                  Copyright © 2021.All Rights Reserved By{" "}
                  <a href="#">Onucrom</a>
                </p>
              </div>
              <div className="col-lg-6">
                <div className="card-list text-right">
                  <p className="copy-right-text">
                    Contact At :
                    <a href="mailto:info@onucrom.com?subject:Query">
                      &nbsp; &nbsp; info@onucrom.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*footer-section end */}

      {/*scroll-to-top start */}
      <div className="scroll-to-top">
        <span className="scroll-icon">
          <i className="fa fa-rocket"></i>
        </span>
      </div>
      {/*scroll-to-top end */}
    </div>
  );
};

export default Footer;
